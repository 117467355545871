// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  SimpleGrid, Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import React, {useEffect, useState} from "react";

import { getUserDetails } from 'api/user';
import {useParams} from "react-router-dom";

export default function Settings() {
  const { userUuid } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getUserDetails(userUuid);
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userUuid]);

  if (loading) {
    return (
        <Flex justify="center" align="center" h="200px">
          <Spinner size="lg" />
        </Flex>
    );
  }

  if (!userData) {
    return <Text>No user data found</Text>;
  }

  return (
    <FormControl>
      <Card>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Account Settings
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can change user account information
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <InputField
            mb='25px'
            me='30px'
            id='username'
            label='Username'
            placeholder={userData.full_name}
          />
          <InputField
            mb='25px'
            id='email'
            label='Email Address'
            placeholder='mail@simmmple.com'
          />
          <InputField
            mb='25px'
            me='30px'
            id='first_name'
            label='First Name'
            placeholder='John'
          />
          <InputField
            mb='25px'
            id='last_name'
            label='Last Name'
            placeholder='Doe'
          />
        </SimpleGrid>
        <InputField id='job' label='Job' placeholder='Web Developer' />
        <TextField
          id='about'
          label='About Me'
          h='100px'
          placeholder='Tell something about yourself in 150 characters!'
        />
        <Button
          variant='brand'
          minW='183px'
          fontSize='sm'
          fontWeight='500'
          ms='auto'>
          Save changes
        </Button>
      </Card>
    </FormControl>
  );
}
