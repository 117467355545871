import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getLatestProducts } from 'api/dashboard';
import { BsArrowRight } from 'react-icons/bs';
import { format, parseISO } from 'date-fns';

export default function LatestProducts(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');

  // State variables
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch latest products
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const data = await getLatestProducts({ limit: 5 });
        console.log(data);
        setProducts(data);
      } catch (error) {
        console.error('Error fetching latest products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Card
      justifyContent="center"
      direction="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      pb="20px"
      {...rest}
    >
      <Text
        color={textColor}
        fontSize="lg"
        fontWeight="700"
        lineHeight="100%"
        mb="26px"
        pt="20px"
      >
        Latest Non-Loyalty Purchases
      </Text>
      {isLoading ? (
        <Flex justify="center" align="center" minH="150px">
          <Spinner />
        </Flex>
      ) : (
        products.map((product) => (
          <Flex key={product.id} alignItems="center" w="100%" mb="15px">
            <Image
              src={`https://api.perxify.com/${product.image}` || ''}
              alt={product.name}
              boxSize="40px"
              borderRadius="full"
              mr="10px"
            />
            <Flex direction="column" align="start" me="auto">
              <Text color={textColor} fontSize="md" fontWeight="700">
                {product.name}
              </Text>
              <Text fontSize="xs" color="secondaryGray.500">
                Purchased on{' '}
                {format(parseISO(product.purchase_date), 'dd MMM yyyy')}
              </Text>
            </Flex>
            <Text ms="auto" color={textColor} fontSize="sm" fontWeight="700">
              €{product.price.toFixed(2)}
            </Text>
          </Flex>
        ))
      )}
      <Button
        p="0px"
        ms="auto"
        variant="no-hover"
        bg="transparent"
        my={{ sm: '1.5rem', lg: '0px' }}
      >
        <Text
          fontSize="sm"
          color={brandColor}
          fontWeight="bold"
          cursor="pointer"
          transition="all .3s ease"
          my={{ sm: '1.5rem', lg: '0px' }}
          _hover={{ me: '4px' }}
        >
          View all
        </Text>
        <Icon
          as={BsArrowRight}
          w="18px"
          h="18px"
          color={brandColor}
          transition="all .3s ease"
          ms=".3rem"
          cursor="pointer"
          _hover={{ transform: 'translate(4px)' }}
        />
      </Button>
    </Card>
  );
}
