import { useEffect } from 'react';
import Cookies from 'js-cookie';

export default function Callback() {
  const token = new URLSearchParams(window.location.search).get('token');

  useEffect(() => {
    if (token) {
      Cookies.set('token', token, {
        secure: process.env.NODE_ENV !== 'development',
        sameSite: 'Strict',
        expires: 7, // 1 week
      });

      window.location.href = '/admin/dashboard/default';
    } else {
      window.location.href = '/auth/failure';
    }
  }, [token]);

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
}