// src/hooks/useLoyaltyLevels.js

import useFetch from './useFetch';
import { getLoyaltyLevels } from '../api/product';

const useLoyaltyLevels = () => {
  const { data, loading, error } = useFetch(getLoyaltyLevels, []);

  return { loyaltyLevels: data || [], loading, error };
};

export default useLoyaltyLevels;
