import apiClient from './axios';

export const getUserDetails = async (userUuid) => {
  try {
    const response = await apiClient.get(`/user/${userUuid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await apiClient.get('/users');
    return response.data.map((user) => ({
      ...user,
      avatar_url: user.avatar_url,
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};
// api/user.js
export const getLoggedInUserDetails = async () => {
  try {
    const response = await apiClient.get('/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching logged-in user details:', error);
    throw error;
  }
};
