// src/pages/discounts/DiscountOverview.jsx

import React, { useState, useEffect, useMemo } from 'react';
import {
  Flex,
  Spinner,
  Text,
  Button,
  useColorModeValue,
  Alert,
  AlertIcon,
  useToast,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate, Outlet } from 'react-router-dom';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { useDebounce } from 'use-debounce';
import Pagination from '../../pages/products/pagination';
import DiscountFilters from '../../components/discounts/DiscountFilters';
import DiscountTable from '../../components/discounts/DiscountTable';
import useDiscountColumns from '../../components/discounts/discountColumns';

// Import custom hooks
import useProducts from '../../hooks/useProducts';
import useLoyaltyPrograms from '../../hooks/useLoyaltyPrograms';
import useLoyaltyLevels from '../../hooks/useLoyaltyLevels';
import useDiscounts from '../../hooks/useDiscounts';
import { createProductDiscount, getDiscount } from '../../api/discount';

export default function DiscountOverview() {
  const navigate = useNavigate();
  const toast = useToast();
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  // Initialize filter states with localStorage
  const [productUUID, setProductUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_productUUID');
    return stored || '';
  });
  const [loyaltyProgramUUID, setLoyaltyProgramUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_loyaltyProgramUUID');
    return stored || '';
  });
  const [loyaltyLevelUUID, setLoyaltyLevelUUID] = useState(() => {
    const stored = localStorage.getItem('discountOverview_loyaltyLevelUUID');
    return stored || '';
  });
  const [isActive, setIsActive] = useState(() => {
    const stored = localStorage.getItem('discountOverview_isActive');
    const parsed = stored !== null ? JSON.parse(stored) : true;
    return parsed;
  });

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 30 });

  // Debounced filter values to optimize API calls
  const [debouncedProductUUID] = useDebounce(productUUID, 300);
  const [debouncedLoyaltyProgramUUID] = useDebounce(loyaltyProgramUUID, 300);
  const [debouncedLoyaltyLevelUUID] = useDebounce(loyaltyLevelUUID, 300);
  const [debouncedIsActive] = useDebounce(isActive, 300);

  // Use custom hooks
  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = useProducts();
  const {
    loyaltyPrograms,
    loading: loyaltyProgramsLoading,
    error: loyaltyProgramsError,
  } = useLoyaltyPrograms();
  const {
    loyaltyLevels,
    loading: loyaltyLevelsLoading,
    error: loyaltyLevelsError,
  } = useLoyaltyLevels();

  // Prepare filters for discounts
  const filters = useMemo(
    () => ({
      product_uuid: debouncedProductUUID || undefined,
      loyalty_program_uuid: debouncedLoyaltyProgramUUID || undefined,
      loyalty_level_uuid: debouncedLoyaltyLevelUUID || undefined,
      is_active: debouncedIsActive,
      page: pagination.pageIndex + 1, // API pages are 1-based
      limit: pagination.pageSize,
    }),
    [
      debouncedProductUUID,
      debouncedLoyaltyProgramUUID,
      debouncedLoyaltyLevelUUID,
      debouncedIsActive,
      pagination.pageIndex,
      pagination.pageSize,
    ],
  );

  const {
    discounts = [],
    total = 0, // Get total count
    loading: discountsLoading,
    error: discountsError,
  } = useDiscounts(filters);
  const rootDiscounts = useMemo(
    () => discounts.filter((d) => !d.parent_uuid),
    [discounts],
  );

  // Debugging: Log discounts and total
  useEffect(() => {
    console.log('Fetched Discounts:', discounts);
    console.log('Total Discounts:', total);
  }, [discounts, total]);

  // **Remove the nestedDiscounts useMemo**
  // const nestedDiscounts = useMemo(() => {
  //   // Nesting logic removed
  //   return discounts;
  // }, [discounts]);

  const handleDuplicate = async (uuid) => {
    try {
      // Fetch the discount data
      const discountData = await getDiscount(uuid);

      // Destructure parent and child discounts
      const {
        parent_discount: parentDiscount,
        child_discounts: childDiscounts,
      } = discountData;

      // Prepare the data for duplication
      const duplicatedData = {
        // Copy the necessary fields from the discount
        ...parentDiscount,
        // Remove UUID and parent_id to create a new discount
        uuid: undefined,
        parent_id: undefined,
        // Ensure is_active is set as per your requirements
        is_active: parentDiscount.is_active,
      };

      // Handle discount_type specific fields
      if (parentDiscount.discount_type === 'selected_products') {
        // Remove product_uuid and include product_uuids
        delete duplicatedData.product_uuid;
        let productUuids = [];

        if (childDiscounts && childDiscounts.length > 0) {
          productUuids = childDiscounts.map((child) => child.product_uuid);
        } else if (parentDiscount.product_uuid) {
          // If there are no child discounts but parent has a product_uuid
          productUuids = [parentDiscount.product_uuid];
        } else {
          // Handle error: No products to duplicate
          throw new Error(
            'No products found to duplicate for selected_products discount.',
          );
        }

        duplicatedData.product_uuids = productUuids;
      } else if (
        parentDiscount.discount_type === 'all_loyalty_products' ||
        parentDiscount.discount_type === 'all_general_products'
      ) {
        // For these types, remove both product_uuid and product_uuids
        delete duplicatedData.product_uuid;
        delete duplicatedData.product_uuids;
      } else {
        // For other types, ensure product_uuid is included and product_uuids is not
        duplicatedData.product_uuid = parentDiscount.product_uuid;
        delete duplicatedData.product_uuids;
      }

      // Remove any undefined fields
      Object.keys(duplicatedData).forEach((key) => {
        if (duplicatedData[key] === undefined) {
          delete duplicatedData[key];
        }
      });

      console.log('Duplicated Data:', duplicatedData);

      // Call the create discount API
      const newDiscount = await createProductDiscount(duplicatedData);

      // Navigate to the edit page of the new discount
      navigate(`/admin/discounts/edit/${newDiscount.parent_discount.uuid}`);

      // Optional: Show a success toast
      toast({
        title: 'Discount duplicated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error duplicating discount:', error);
      // Optional: Show an error toast
      toast({
        title: 'Failed to duplicate discount.',
        description:
          error.response?.data?.error ||
          error.message ||
          'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Define columns using the enhanced useDiscountColumns hook
  const columns = useDiscountColumns(textColor, navigate, handleDuplicate);

  // Initialize the table instance
  const table = useReactTable({
    data: discounts, // Use discounts directly since they are already nested
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pagination.pageSize),
    getSubRows: (row) => row.children || [],
  });

  // Effect to ensure pageIndex is within valid range
  useEffect(() => {
    const totalPages = table.getPageCount();
    if (pagination.pageIndex >= totalPages && totalPages > 0) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: totalPages - 1,
      }));
    }
  }, [table.getPageCount(), pagination.pageIndex, setPagination]);

  // Reset filters if selected option is no longer available
  useEffect(() => {
    if (productUUID && !discounts.some((d) => d.uuid === productUUID)) {
      console.warn(
        `Product UUID ${productUUID} not found in fetched discounts. Resetting filter.`,
      );
      setProductUUID('');
    }
  }, [discounts, productUUID]);

  useEffect(() => {
    if (
      loyaltyProgramUUID &&
      !discounts.some((d) => d.loyalty_program_uuid === loyaltyProgramUUID)
    ) {
      console.warn(
        `Loyalty Program UUID ${loyaltyProgramUUID} not found in fetched discounts. Resetting filter.`,
      );
      setLoyaltyProgramUUID('');
    }
  }, [discounts, loyaltyProgramUUID]);

  useEffect(() => {
    if (
      loyaltyLevelUUID &&
      !discounts.some((d) => d.loyalty_level_uuid === loyaltyLevelUUID)
    ) {
      console.warn(
        `Loyalty Level UUID ${loyaltyLevelUUID} not found in fetched discounts. Resetting filter.`,
      );
      setLoyaltyLevelUUID('');
    }
  }, [discounts, loyaltyLevelUUID]);

  // Handler to reset all filters
  const resetFilters = () => {
    setProductUUID('');
    setLoyaltyProgramUUID('');
    setLoyaltyLevelUUID('');
    setIsActive(true);
    setPagination({ pageIndex: 0, pageSize: 30 }); // Reset to first page
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Flex
        direction="row"
        mb="20px"
        px="20px"
        align="center"
        justifyContent="space-between"
      >
        <DiscountFilters
          productUUID={productUUID}
          setProductUUID={setProductUUID}
          loyaltyProgramUUID={loyaltyProgramUUID}
          setLoyaltyProgramUUID={setLoyaltyProgramUUID}
          loyaltyLevelUUID={loyaltyLevelUUID}
          setLoyaltyLevelUUID={setLoyaltyLevelUUID}
          isActive={isActive}
          setIsActive={setIsActive}
          resetFilters={resetFilters}
          filteredProducts={products.filter((product) =>
            discounts.some((d) => d.product_uuid === product.uuid),
          )}
          filteredLoyaltyPrograms={loyaltyPrograms.filter((program) =>
            discounts.some((d) => d.loyalty_program_uuid === program.uuid),
          )}
          filteredLoyaltyLevels={loyaltyLevels.filter((level) =>
            discounts.some((d) => d.loyalty_level_uuid === level.uuid),
          )}
          setPagination={setPagination}
        />

        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
          onClick={() => navigate('/admin/discounts/new-discount')}
        >
          Add Discount
        </Button>
      </Flex>

      {/* Display Error Messages */}
      {(productsError ||
        loyaltyProgramsError ||
        loyaltyLevelsError ||
        discountsError) && (
        <Alert status="error" mb="20px" mx="20px">
          <AlertIcon />
          {productsError ||
            loyaltyProgramsError ||
            loyaltyLevelsError ||
            discountsError}
        </Alert>
      )}

      {discountsLoading ||
      productsLoading ||
      loyaltyProgramsLoading ||
      loyaltyLevelsLoading ? (
        <Flex justify="center" align="center" h="200px">
          <Spinner size="lg" />
        </Flex>
      ) : discounts.length > 0 ? (
        <>
          {/* Table */}
          <DiscountTable
            table={table}
            borderColor={borderColor}
            textColor={textColor}
          />
          {/* Show pagination only if there are more than pageSize discounts */}
          {total > pagination.pageSize && (
            <Flex
              w="100%"
              justify="space-between"
              px="20px"
              pt="10px"
              pb="5px"
              alignItems="center"
            >
              <Text fontSize="sm" color="gray.500" fontWeight="normal">
                Showing{' '}
                {total === 0
                  ? 0
                  : pagination.pageIndex * pagination.pageSize + 1}{' '}
                to{' '}
                {Math.min(
                  (pagination.pageIndex + 1) * pagination.pageSize,
                  total,
                )}{' '}
                of {total} entries
              </Text>

              <Pagination
                pageIndex={pagination.pageIndex}
                pageCount={table.getPageCount()}
                canNextPage={table.getCanNextPage()}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                previousPage={table.previousPage}
                setPageIndex={table.setPageIndex}
                textColor={textColor}
                borderColor={borderColor}
                brandColor={brandColor}
              />
            </Flex>
          )}
        </>
      ) : (
        <Text textAlign="center" mt="20px">
          No discounts found.
        </Text>
      )}
      <Outlet />
    </Flex>
  );
}
