import Cookies from 'js-cookie';

export default function decodeCookie(token) {
  if (!token) return null;

  try {
    // Decode the JWT payload
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );

    // Parse the JSON payload
    const data = JSON.parse(jsonPayload);

    // Extract company information
    const companyInfo = {
      uuid: data.uuid,
      name: data.name,
    };

    return companyInfo;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}
