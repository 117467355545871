import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Select,
} from '@chakra-ui/react';

export default function FlowModal({
  isOpen,
  onClose,
  isEditing,
  currentStep,
  setCurrentStep,
  handleSave,
  renderConditionInputs,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEditing ? 'Edit Step' : 'Add New Step'}</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Select
              placeholder="Select step type"
              value={currentStep.step_type}
              onChange={(e) =>
                setCurrentStep({ ...currentStep, step_type: e.target.value })
              }
            >
              <option value="IF_AND">IF (AND)</option>
              <option value="IF_OR">IF (OR)</option>
              <option value="THEN">THEN</option>
            </Select>

            {(currentStep.step_type === 'IF_AND' ||
              currentStep.step_type === 'IF_OR') && (
              <>
                <Select
                  placeholder="Select condition type"
                  value={currentStep.condition_type}
                  onChange={(e) =>
                    setCurrentStep({
                      ...currentStep,
                      condition_type: e.target.value,
                    })
                  }
                >
                  <option value="loyaltyProgram">
                    User has loyalty program X
                  </option>
                  <option value="loyaltyLevel">
                    User has loyalty program X on level Y
                  </option>
                  <option value="spendAmount">
                    User has spent X over Y period
                  </option>
                </Select>
                {renderConditionInputs()}
              </>
            )}
            {currentStep.step_type === 'THEN' && (
              <>
                <Select
                  placeholder="Select condition type"
                  value={currentStep.condition_type}
                  onChange={(e) =>
                    setCurrentStep({
                      ...currentStep,
                      condition_type: e.target.value,
                    })
                  }
                >
                  <option value="rewardVoucher">
                    User gets voucher for product X
                  </option>
                </Select>
                {renderConditionInputs()}
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
