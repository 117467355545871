import { FiChevronsDown } from 'react-icons/fi';
import { TbArrowsSplit, TbAmpersand } from 'react-icons/tb';

export const getLoyaltyProgramName = (uuid, loyaltyPrograms) => {
  const program = loyaltyPrograms.find((program) => program.uuid === uuid);
  return program ? program.name : 'Unknown Program';
};

export const getLoyaltyLevelName = (uuid, loyaltyPrograms) => {
  const level = loyaltyPrograms
    .flatMap((program) => program.products)
    .find((level) => level.loyalty_level_uuid === uuid);
  return level ? level.name : 'Unknown Level';
};

export const getRewardVoucherName = (uuid, vouchers) => {
  const voucher = vouchers.find((voucher) => voucher.uuid === uuid);
  return voucher ? voucher.name : 'Unknown Voucher';
};

export const getIconBetweenSteps = (currentType, nextType, prevType) => {
  if (nextType === 'THEN') {
    return FiChevronsDown;
  }
  if (currentType === 'IF_AND' && nextType === 'IF_OR') {
    return TbArrowsSplit;
  }
  if (prevType === 'IF_OR' && currentType === 'IF_AND') {
    return TbArrowsSplit;
  }
  return currentType === 'IF_AND' ? TbAmpersand : TbArrowsSplit;
};
