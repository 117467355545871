import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { MdEdit } from 'react-icons/md';
import { RiArchiveFill, RiInboxUnarchiveFill } from 'react-icons/ri';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  createLoyaltyProgram,
  updateLoyaltyProgram,
  getLoyaltyPrograms,
} from 'api/loyalty';

export default function LoyaltyOverview() {
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLoyaltyData, setNewLoyaltyData] = useState({
    name: '',
    description: '',
  });
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();
  const cancelRef = React.useRef();
  const toast = useToast();

  const handleArchive = async () => {
    try {
      const selectedProgram = loyaltyPrograms.find(
        (program) => program.uuid === selectedProgramId,
      );
      const newStatus = !selectedProgram.is_archived;

      await updateLoyaltyProgram(selectedProgramId, {
        is_archived: newStatus,
      });

      toast({
        title: `Loyalty program ${newStatus ? 'archived' : 'unarchived'}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      const updatedPrograms = loyaltyPrograms.map((program) =>
        program.uuid === selectedProgramId
          ? { ...program, is_archived: newStatus }
          : program,
      );
      setLoyaltyPrograms(updatedPrograms);
    } catch (error) {
      console.error('Error:', error);
      const selectedProgram = loyaltyPrograms.find(
        (program) => program.uuid === selectedProgramId,
      );
      const newStatus = !selectedProgram.is_archived;
      toast({
        title: `Error ${newStatus ? 'archiving' : 'unarchiving'} loyalty program`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsArchiveDialogOpen(false);
    }
  };

  useEffect(() => {
    const fetchLoyaltyPrograms = async () => {
      try {
        const programs = await getLoyaltyPrograms();
        setLoyaltyPrograms(programs);
      } catch (error) {
        console.error('Error fetching loyalty programs:', error);
      }
    };

    fetchLoyaltyPrograms();
  }, []);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '14px' }}
          color="gray.400"
          textAlign="center"
        >
          Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '14px' }}
          color="gray.400"
          textAlign="center"
        >
          Description
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '14px' }}
          color="gray.400"
          textAlign="center"
        >
          Date Created
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('is_archived', {
      id: 'is_archived',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '14px' }}
          color="gray.400"
          textAlign="center"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const isArchived = info.row.original.is_archived;
        const isActive = info.row.original.is_active;
        let status = 'Active';
        if (isArchived) {
          status = 'Archived';
        } else if (!isActive) {
          status = 'Inactive';
        }
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {status}
          </Text>
        );
      },
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="center"
          align="center"
          fontSize={{ sm: '10px', lg: '14px' }}
          color="gray.400"
          textAlign="center"
        >
          ACTIONS
        </Text>
      ),
      cell: (info) => (
        <Flex justify="center" align="center">
          <HStack spacing="4">
            <Icon
              w="16px"
              h="16px"
              as={MdEdit}
              cursor="pointer"
              color={brandColor}
              onClick={() => {
                const programId = info.row.original.uuid;
                if (programId) {
                  navigate(`/admin/loyalty-programs/${programId}`);
                } else {
                  console.error('programId is undefined');
                }
              }}
            />
            <Icon
              w="16px"
              h="16px"
              as={
                info.row.original.is_archived
                  ? RiInboxUnarchiveFill
                  : RiArchiveFill
              }
              cursor="pointer"
              color={info.row.original.is_archived ? brandColor : 'red.500'}
              onClick={() => {
                const programId = info.row.original.uuid;
                if (programId) {
                  setSelectedProgramId(programId);
                  setIsArchiveDialogOpen(true);
                } else {
                  console.error('programId is undefined');
                }
              }}
            />
          </HStack>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: loyaltyPrograms,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleModalInputChange = (e) => {
    const { id, value } = e.target;
    setNewLoyaltyData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleModalSave = async () => {
    const response = await createLoyaltyProgram(newLoyaltyData);

    if (response) {
      navigate(`/admin/loyalty-programs/${response.uuid}`);
    } else {
      toast({
        title: 'Error creating loyalty program',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const createPages = (count) => {
    let arrPageCount = [];
    for (let i = 0; i < count; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      mt={{ sm: '150px', md: '125px', lg: '75px' }}
    >
      <Flex
        align={{ sm: 'flex-start', lg: 'flex-start' }}
        justify={{ sm: 'flex-start', lg: 'flex-start' }}
        w="100%"
        px="22px"
        mb="36px"
      >
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search..."
        />
        <Button
          ml="auto"
          size="md"
          colorScheme="blue"
          onClick={openModal}
          leftIcon={<AddIcon />}
        >
          New Loyalty
        </Button>
      </Flex>
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  borderColor={borderColor}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <Flex
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted()] ?? null}
                    </Flex>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr px="20px" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Tooltip
                  label={cell.getValue()}
                  aria-label="Full text"
                  borderRadius="md"
                  key={cell.id}
                  bg="gray.600"
                >
                  <Td
                    key={cell.id}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    maxW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor={borderColor}
                    align="center"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                </Tooltip>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        <Text
          fontSize="sm"
          color="gray.500"
          fontWeight="normal"
          mb={{ sm: '24px', md: '0px' }}
        >
          Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
          {pagination.pageSize * (pagination.pageIndex + 1) <=
          loyaltyPrograms.length
            ? pagination.pageSize * (pagination.pageIndex + 1)
            : loyaltyPrograms.length}{' '}
          of {loyaltyPrograms.length} entries
        </Text>
        <div className="flex items-center gap-2">
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pagination.pageSize === 5
                  ? 'none'
                  : table.getCanPreviousPage()
                    ? 'flex'
                    : 'none'
              }
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
            </Button>
            {createPages(table.getPageCount()).map((pageNumber, index) => (
              <Button
                variant="no-effects"
                transition="all .5s ease"
                onClick={() => table.setPageIndex(pageNumber - 1)}
                w="40px"
                h="40px"
                borderRadius="50%"
                bg={
                  pageNumber === pagination.pageIndex + 1
                    ? brandColor
                    : 'transparent'
                }
                border={
                  pageNumber === pagination.pageIndex + 1
                    ? 'none'
                    : '1px solid lightgray'
                }
                _hover={
                  pageNumber === pagination.pageIndex + 1
                    ? {
                        opacity: '0.7',
                      }
                    : {
                        bg: 'whiteAlpha.100',
                      }
                }
                key={index}
              >
                <Text
                  fontSize="sm"
                  color={
                    pageNumber === pagination.pageIndex + 1 ? '#fff' : textColor
                  }
                >
                  {pageNumber}
                </Text>
              </Button>
            ))}
            <Button
              variant="no-effects"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pagination.pageSize === 5
                  ? 'none'
                  : table.getCanNextPage()
                    ? 'flex'
                    : 'none'
              }
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
            </Button>
          </Stack>
        </div>
      </Flex>

      <AlertDialog
        isOpen={isArchiveDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsArchiveDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Archive Loyalty Program
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to archive this loyalty program?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsArchiveDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleArchive} ml={3}>
                Archive
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Loyalty Program</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="15px">
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                placeholder="Name"
                value={newLoyaltyData.name}
                onChange={handleModalInputChange}
              />
            </FormControl>
            <FormControl mb="15px">
              <FormLabel htmlFor="description">Description</FormLabel>
              <Input
                id="description"
                placeholder="Description"
                value={newLoyaltyData.description}
                onChange={handleModalInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleModalSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      h="44px"
      w={{ lg: '390px' }}
      borderRadius="16px"
    />
  );
}
