import React from 'react';
import { Box, Flex, Avatar, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function Banner({
  banner,
  avatar,
  name,
  purchases,
  moneySpent,
  claims,
}) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );

  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems="center">
      <Box
        bgGradient="linear(to-b, brand.400, brand.600)"
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={avatar}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {purchases ? purchases : 0}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Purchases
          </Text>
        </Flex>
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {moneySpent ? moneySpent : 0}€
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Money Spent
          </Text>
        </Flex>
        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {claims ? claims.length : 0}€
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Claims
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}