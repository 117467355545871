import apiClient from '../api/axios';
import Cookies from 'js-cookie';

// Fetch companies using the apiClient
export const fetchCompanies = async () => {
  const token = Cookies.get('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  const response = await apiClient.get('/companies', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

// Set company cookie function
export const setCompanyCookie = (company) => {
  if (company) {
    const companyToken = company.x_company_token;
    const companyUuid = company.uuid;
    // Secure the cookie with proper settings
    Cookies.set('x-company', companyToken, {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
      expires: 7, // 1 week
    });
    Cookies.set('companyUuid', companyUuid, {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
      expires: 7, // 1 week
    });
  } else {
    throw new Error('No companies found for this user');
  }
};
