import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import CompanySelector from '../../../components/company/CompanySelector';
import {
  fetchCompanies,
  setCompanyCookie,
} from '../../../services/companyService';
import { setAuthCookies } from './setAuthCookies'; // Correct import path
import Cookies from 'js-cookie'; // Ensure Cookies is imported

export default function GoogleAuthCallback() {
  const [token, setToken] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const handleCompanySelected = useCallback(
    (company) => {
      setCompanyCookie(company);
      setAuthCookies(token, company); // Set both token and company in cookies
      setSelectedCompany(company);
      navigate('/admin/dashboard');
    },
    [navigate, token],
  );

  const handleFetchCompanies = useCallback(
    async (token) => {
      try {
        setAuthCookies(token); // Ensure token is set in cookies

        const companies = await fetchCompanies();
        setCompanies(companies);

        if (companies.length === 1) {
          handleCompanySelected(companies[0]);
        }
      } catch (err) {
        console.error('Error fetching companies:', err);
        toast({
          title: 'An error occurred.',
          description: 'Failed to fetch companies',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        navigate('/auth/sign-in');
      }
    },
    [handleCompanySelected, toast, navigate],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get('token');

    if (tokenFromURL) {
      setToken(tokenFromURL);
      setAuthCookies(tokenFromURL); // Set token in cookies

      handleFetchCompanies(tokenFromURL);
    } else {
      toast({
        title: 'An error occurred.',
        description: 'Google sign-in failed',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      navigate('/auth/sign-in');
    }
  }, [navigate, toast, handleFetchCompanies]);

  return (
    <>
      {token && companies.length > 1 && !selectedCompany && (
        <CompanySelector
          token={token}
          onCompanySelected={handleCompanySelected}
        />
      )}
    </>
  );
}
