import React, { useState, useEffect, useMemo } from 'react';
import {
  Flex,
  Checkbox,
  Spinner,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { getProducts } from '../../api/product';
import Pagination from './pagination'; // Import the new Pagination component

export default function ProductOverview() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [featured, setFeatured] = useState(false);
  const [showLoyaltyProducts, setShowLoyaltyProducts] = useState(false); // New state variable
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  // Fetch all products once when the component mounts
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const productsData = await getProducts();
        setProducts(productsData);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []); // Empty dependency array ensures this runs only once

  // Compute filtered products based on featured and loyalty filters
  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      const isRegularProduct = product.loyalty_program_uuid === null;
      const isLoyaltyProduct = product.loyalty_program_uuid !== null;

      if (showLoyaltyProducts) {
        return isLoyaltyProduct && (!featured || product.is_featured);
      } else {
        return isRegularProduct && (!featured || product.is_featured);
      }
    });
  }, [products, featured, showLoyaltyProducts]);

  const columnHelper = createColumnHelper();
  const columns = [
    // Name Column
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex direction="column">
          <Text color={textColor} fontSize="md" fontWeight="500">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    // Price Column
    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          PRICE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          €{info.getValue()}
        </Text>
      ),
    }),
    // Featured Column
    columnHelper.accessor('is_featured', {
      id: 'is_featured',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          FEATURED
        </Text>
      ),
      cell: (info) => (
        <Badge colorScheme={info.getValue() ? 'green' : 'gray'}>
          {info.getValue() ? 'Yes' : 'No'}
        </Badge>
      ),
    }),
    // Stock Column
    columnHelper.display({
      id: 'stock',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          STOCK
        </Text>
      ),
      cell: (info) => {
        const { quantity, sold_quantity } = info.row.original;
        const stock = Math.max((quantity || 0) - (sold_quantity || 0), 0); // Ensure stock is not negative
        return (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {stock}
          </Text>
        );
      },
    }),
    // Actions Column
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          ACTIONS
        </Text>
      ),
      cell: (info) => (
        <Text
          w="16px"
          h="16px"
          as={MdEdit}
          cursor="pointer"
          color={brandColor}
          onClick={() =>
            navigate(`/admin/products/edit/${info.row.original.uuid}`)
          }
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: filteredProducts, // Use filtered products here
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  // Effect to ensure pageIndex is within valid range
  useEffect(() => {
    const totalPages = table.getPageCount();
    if (pagination.pageIndex >= totalPages && totalPages > 0) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: totalPages - 1,
      }));
    }
  }, [table.getPageCount(), pagination.pageIndex, setPagination]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Flex
        direction="row"
        mb="20px"
        px="20px"
        align="center"
        justifyContent="space-between"
      >
        <Flex>
          {/* Featured Toggle */}
          <FormControl display="flex" alignItems="center" mr={4}>
            <FormLabel
              htmlFor="featured"
              mb="0"
              fontSize="md"
              fontWeight="500"
              color={textColor}
            >
              Featured
            </FormLabel>
            <Switch
              id="featured"
              colorScheme="blue"
              isChecked={featured}
              onChange={(e) => {
                setFeatured(e.target.checked);
                setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page on filter change
              }}
            />
          </FormControl>

          {/* Loyalty Products Toggle */}
          <FormControl display="flex" alignItems="center">
            <FormLabel
              htmlFor="loyalty-products"
              mb="0"
              fontSize="md"
              fontWeight="500"
              color={textColor}
            >
              Loyalty Products
            </FormLabel>
            <Switch
              id="loyalty-products"
              colorScheme="blue"
              isChecked={showLoyaltyProducts}
              onChange={(e) => {
                setShowLoyaltyProducts(e.target.checked);
                setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page on filter change
              }}
            />
          </FormControl>
        </Flex>

        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
          onClick={() => navigate('/admin/products/new-product')}
        >
          Add Product
        </Button>
      </Flex>

      {loading ? (
        <Flex justify="center" align="center" h="200px">
          <Spinner size="lg" />
        </Flex>
      ) : filteredProducts.length > 0 ? (
        <>
          {/* Table */}
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      borderColor={borderColor}
                      cursor={
                        header.column.getCanSort() ? 'pointer' : 'default'
                      }
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex align="center" justify="space-between">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {/* Sorting Indicator */}
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? (
                            <Text fontSize="xs">↓</Text>
                          ) : (
                            <Text fontSize="xs">↑</Text>
                          )
                        ) : (
                          header.column.getCanSort() && (
                            <Text fontSize="xs">⇅</Text>
                          )
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} borderColor={borderColor}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Show pagination only if there are more than 10 products */}
          {filteredProducts.length > 10 && (
            <Flex
              w="100%"
              justify="space-between"
              px="20px"
              pt="10px"
              pb="5px"
              alignItems="center"
            >
              <Text fontSize="sm" color="gray.500" fontWeight="normal">
                Showing{' '}
                {filteredProducts.length === 0
                  ? 0
                  : pagination.pageIndex * pagination.pageSize + 1}{' '}
                to{' '}
                {Math.min(
                  (pagination.pageIndex + 1) * pagination.pageSize,
                  filteredProducts.length,
                )}{' '}
                of {filteredProducts.length} entries
              </Text>

              <Pagination
                pageIndex={pagination.pageIndex}
                pageCount={table.getPageCount()}
                canNextPage={table.getCanNextPage()}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                previousPage={table.previousPage}
                setPageIndex={table.setPageIndex}
                textColor={textColor}
                borderColor={borderColor}
                brandColor={brandColor}
              />
            </Flex>
          )}
        </>
      ) : (
        <Text textAlign="center" mt="20px">
          No products found.
        </Text>
      )}
    </Flex>
  );
}
