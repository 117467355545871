import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
  VStack,
  Alert,
  AlertIcon,
  Box,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  CheckboxGroup,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getDiscount,
  updateProductDiscount,
  deleteProductDiscount,
} from '../../api/discount';
import useProducts from '../../hooks/useProducts';
import useLoyaltyPrograms from '../../hooks/useLoyaltyPrograms';
import useLoyaltyLevels from '../../hooks/useLoyaltyLevels';
import ProductCheckboxCard from './ProductCheckboxCard';

const EditDiscount = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [discountKind, setDiscountKind] = useState('fixed_price');
  const [discountScope, setDiscountScope] = useState('');
  const [isParent, setIsParent] = useState(false);
  const [formData, setFormData] = useState({
    product_uuid: '',
    product_uuids: [],
    discount_price: '',
    discount_percentage: '',
    loyalty_program_uuid: '',
    loyalty_level_uuid: '',
    setPeriod: false,
    start_time: '',
    end_time: '',
    is_active: true,
  });

  const {
    products,
    loading: productsLoading,
    error: productsError,
  } = useProducts();

  const {
    loyaltyPrograms,
    loading: loyaltyProgramsLoading,
    error: loyaltyProgramsError,
  } = useLoyaltyPrograms();
  const {
    loyaltyLevels,
    loading: loyaltyLevelsLoading,
    error: loyaltyLevelsError,
  } = useLoyaltyLevels();

  const [filteredLoyaltyLevels, setFilteredLoyaltyLevels] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    const fetchDiscount = async () => {
      try {
        const data = await getDiscount(uuid);
        const isEditingParent =
          data.parent_discount && data.parent_discount.uuid === uuid;

        let selectedProductUUIDs = [];
        if (isEditingParent) {
          selectedProductUUIDs = (data.child_discounts || [])
            .map((child) => child.product_uuid)
            .filter((uuid) => uuid);
        } else {
          const childDiscount = data.child_discounts.find(
            (child) => child.uuid === uuid,
          );
          selectedProductUUIDs = childDiscount
            ? [childDiscount.product_uuid]
            : [];
        }

        setFormData({
          product_uuid: isEditingParent
            ? ''
            : data.parent_discount.product_uuid || '',
          product_uuids: selectedProductUUIDs,
          discount_price: data.parent_discount.discount_price || '',
          discount_percentage: data.parent_discount.discount_percentage || '',
          loyalty_program_uuid: data.parent_discount.loyalty_program_uuid || '',
          loyalty_level_uuid: data.parent_discount.loyalty_level_uuid || '',
          setPeriod:
            data.parent_discount.start_time || data.parent_discount.end_time
              ? true
              : false,
          start_time: data.parent_discount.start_time
            ? new Date(data.parent_discount.start_time)
                .toISOString()
                .slice(0, 16)
            : '',
          end_time: data.parent_discount.end_time
            ? new Date(data.parent_discount.end_time).toISOString().slice(0, 16)
            : '',
          is_active: data.parent_discount.is_active,
        });
        setIsParent(isEditingParent);
        setDiscountScope(data.parent_discount.discount_type);
        setDiscountKind(
          data.parent_discount.discount_price !== null
            ? 'fixed_price'
            : 'percentage',
        );
      } catch (err) {
        setError('Failed to fetch discount details.');
      } finally {
        setLoading(false);
      }
    };

    fetchDiscount();
  }, [uuid]);

  useEffect(() => {
    if (formData.loyalty_program_uuid) {
      const filtered = loyaltyLevels.filter(
        (level) => level.loyalty_program_uuid === formData.loyalty_program_uuid,
      );
      setFilteredLoyaltyLevels(filtered);

      const isValidLevel = filtered.some(
        (level) => level.uuid === formData.loyalty_level_uuid,
      );
      if (!isValidLevel) {
        if (!isInitialLoad) {
          setFormData((prev) => ({ ...prev, loyalty_level_uuid: '' }));
        }
      }
    } else {
      setFilteredLoyaltyLevels(loyaltyLevels);
      if (!isInitialLoad) {
        setFormData((prev) => ({ ...prev, loyalty_level_uuid: '' }));
      }
    }

    if (
      isInitialLoad &&
      !loading &&
      !loyaltyLevelsLoading &&
      !loyaltyProgramsLoading
    ) {
      setIsInitialLoad(false);
    }
  }, [
    formData.loyalty_program_uuid,
    loyaltyLevels,
    isInitialLoad,
    loading,
    loyaltyLevelsLoading,
    loyaltyProgramsLoading,
    formData.loyalty_level_uuid,
  ]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleProductChange = (selectedValues) => {
    setFormData((prev) => ({
      ...prev,
      product_uuids: selectedValues,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (discountScope === 'selected_products') {
      if (!formData.product_uuids || formData.product_uuids.length === 0) {
        setError(
          'At least one product must be selected for Selected Products scope.',
        );
        return;
      }

      formData.product_uuids = formData.product_uuids.filter(
        (uuid) => uuid && uuid.trim() !== '',
      );

      if (formData.product_uuids.length === 0) {
        setError(
          'At least one valid product must be selected for Selected Products scope.',
        );
        return;
      }
    }

    if (formData.setPeriod) {
      if (!formData.start_time && !formData.end_time) {
        setError(
          'At least one of Start Time or End Time must be set when setting a period.',
        );
        return;
      }
      if (formData.start_time && formData.end_time) {
        if (new Date(formData.start_time) > new Date(formData.end_time)) {
          setError('Start Time must be before End Time.');
          return;
        }
      }
    }

    if (discountKind === 'fixed_price' && !formData.discount_price) {
      setError('Discount Price is required for Fixed Price Discount.');
      return;
    }

    if (discountKind === 'percentage' && !formData.discount_percentage) {
      setError('Discount Percentage is required for Percentage Discount.');
      return;
    }

    if (!discountScope) {
      setError('Discount Scope is required.');
      return;
    }

    if (discountScope !== 'all_products') {
      if (formData.loyalty_program_uuid && formData.loyalty_level_uuid) {
        const isValidLevel = loyaltyLevels.some(
          (level) =>
            level.uuid === formData.loyalty_level_uuid &&
            level.loyalty_program_uuid === formData.loyalty_program_uuid,
        );
        if (!isValidLevel) {
          setError(
            'Selected Loyalty Level does not belong to the chosen Loyalty Program.',
          );
          return;
        }
      }
    }

    try {
      const commonData = {
        ...(discountScope === 'selected_products' &&
        formData.product_uuids.length > 0
          ? { product_uuids: formData.product_uuids }
          : {}),
        start_time:
          formData.setPeriod && formData.start_time
            ? new Date(formData.start_time).toISOString()
            : null,
        end_time:
          formData.setPeriod && formData.end_time
            ? new Date(formData.end_time).toISOString()
            : null,
        is_active: formData.is_active,
        discount_type: discountScope,
      };

      let updateData = { ...commonData };

      switch (discountKind) {
        case 'fixed_price':
          updateData.discount_price = parseFloat(formData.discount_price);
          updateData.discount_percentage = null;
          break;
        case 'percentage':
          updateData.discount_percentage = parseFloat(
            formData.discount_percentage,
          );
          updateData.discount_price = null;
          break;
        default:
          throw new Error('Invalid discount kind selected.');
      }

      if (discountScope !== 'all_products') {
        if (formData.loyalty_program_uuid) {
          updateData.loyalty_program_uuid = formData.loyalty_program_uuid;
        } else {
          updateData.loyalty_program_uuid = null;
        }

        if (formData.loyalty_level_uuid) {
          updateData.loyalty_level_uuid = formData.loyalty_level_uuid;
        } else {
          updateData.loyalty_level_uuid = null;
        }
      } else {
        updateData.loyalty_program_uuid = null;
        updateData.loyalty_level_uuid = null;
      }

      console.log('Submitting Update with Data:', updateData);

      await updateProductDiscount(uuid, updateData);
      setSuccess('Discount updated successfully!');
      navigate('/admin/discounts');
    } catch (err) {
      console.error('Error updating discount:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(`Failed to update discount: ${err.response.data.error}`);
      } else {
        setError(err.message || 'Failed to update discount.');
      }
    }
  };

  const handleProductToggle = (productUuid) => {
    setFormData((prev) => {
      const newProductUuids = prev.product_uuids.includes(productUuid)
        ? prev.product_uuids.filter((uuid) => uuid !== productUuid)
        : [...prev.product_uuids, productUuid];
      return {
        ...prev,
        product_uuids: newProductUuids,
      };
    });
  };

  const handleDelete = async () => {
    try {
      await deleteProductDiscount(uuid);
      setSuccess('Discount deleted successfully!');
      navigate('/admin/discounts');
    } catch (err) {
      console.error('Error deleting discount:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(`Failed to delete discount: ${err.response.data.error}`);
      } else {
        setError(err.message || 'Failed to delete discount.');
      }
    } finally {
      onClose();
    }
  };

  if (
    loading ||
    productsLoading ||
    loyaltyProgramsLoading ||
    loyaltyLevelsLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  let filteredProducts = products.filter(
    (product) => product.product_type === 'regular',
  );

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl id="discountKind" isRequired>
            <FormLabel>Discount Kind</FormLabel>
            <Select
              value={discountKind}
              onChange={(e) => {
                setDiscountKind(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  discount_price: '',
                  discount_percentage: '',
                }));
              }}
            >
              <option value="fixed_price">Discount Amount</option>
              <option value="percentage">Percentage</option>
            </Select>
          </FormControl>
          <FormControl id="discountScope" isRequired>
            <FormLabel>Discount Scope</FormLabel>
            <Select
              value={discountScope}
              onChange={(e) => {
                setDiscountScope(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  product_uuid: '',
                  product_uuids: [],
                }));
                setIsParent(false);
              }}
              placeholder="Select Discount Scope"
            >
              <option value="selected_products">Selected Products</option>
              <option value="all_products">All Products</option>
            </Select>
          </FormControl>
          {discountScope === 'all_products' && (
            <Alert status="info">
              <AlertIcon />
              This discount applies to all products.
            </Alert>
          )}
          {discountScope === 'selected_products' &&
            filteredProducts &&
            !productsLoading && (
              <FormControl id="product_uuids" isRequired>
                <FormLabel>Select Products</FormLabel>
                {productsLoading ? (
                  <Spinner size="sm" />
                ) : productsError ? (
                  <Alert status="error">
                    <AlertIcon />
                    {productsError}
                  </Alert>
                ) : (
                  <Box>
                    <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                      {filteredProducts.map((product) => (
                        <ProductCheckboxCard
                          key={product.uuid}
                          product={product}
                          isChecked={formData.product_uuids.includes(
                            product.uuid,
                          )}
                          onChange={handleProductToggle}
                        />
                      ))}
                    </SimpleGrid>
                  </Box>
                )}
              </FormControl>
            )}
          {discountKind === 'fixed_price' && (
            <FormControl id="discount_price" isRequired>
              <FormLabel>Discount Amount</FormLabel>
              <Input
                type="number"
                name="discount_price"
                value={formData.discount_price}
                onChange={handleChange}
                placeholder="Enter Discount Amount"
                min="0"
                step="0.01"
              />
            </FormControl>
          )}
          {discountKind === 'percentage' && (
            <FormControl id="discount_percentage" isRequired>
              <FormLabel>Discount Percentage</FormLabel>
              <Input
                type="number"
                name="discount_percentage"
                value={formData.discount_percentage}
                onChange={handleChange}
                placeholder="Enter Discount Percentage"
                min="0"
                max="100"
                step="0.1"
              />
            </FormControl>
          )}
          {discountScope !== 'all_products' && (
            <>
              <FormControl id="loyalty_program_uuid">
                <FormLabel>Loyalty Program</FormLabel>
                {loyaltyProgramsLoading ? (
                  <Spinner size="sm" />
                ) : loyaltyProgramsError ? (
                  <Alert status="error">
                    <AlertIcon />
                    {loyaltyProgramsError}
                  </Alert>
                ) : (
                  <Select
                    name="loyalty_program_uuid"
                    value={formData.loyalty_program_uuid}
                    onChange={handleChange}
                    placeholder="Select Loyalty Program"
                  >
                    {loyaltyPrograms.map((program) => (
                      <option key={program.uuid} value={program.uuid}>
                        {program.name}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
              {formData.loyalty_program_uuid && (
                <FormControl id="loyalty_level_uuid">
                  <FormLabel>Loyalty Level (Optional)</FormLabel>
                  {loyaltyLevelsLoading ? (
                    <Spinner size="sm" />
                  ) : loyaltyLevelsError ? (
                    <Alert status="error">
                      <AlertIcon />
                      {loyaltyLevelsError}
                    </Alert>
                  ) : (
                    <Select
                      name="loyalty_level_uuid"
                      value={formData.loyalty_level_uuid}
                      onChange={handleChange}
                      placeholder="Select Loyalty Level (Optional)"
                    >
                      {filteredLoyaltyLevels.map((level) => (
                        <option key={level.uuid} value={level.uuid}>
                          {level.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )}
            </>
          )}
          <FormControl id="setPeriod">
            <Checkbox
              name="setPeriod"
              isChecked={formData.setPeriod}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setFormData((prev) => ({
                  ...prev,
                  setPeriod: isChecked,
                  start_time: isChecked ? prev.start_time : '',
                  end_time: isChecked ? prev.end_time : '',
                }));
              }}
            >
              Period (optional, if not set then always on)
            </Checkbox>
          </FormControl>
          {formData.setPeriod && (
            <>
              <FormControl id="start_time">
                <FormLabel>Start Date (starting from)</FormLabel>
                <Input
                  type="datetime-local"
                  name="start_time"
                  value={formData.start_time}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="end_time">
                <FormLabel>End Date (up to)</FormLabel>
                <Input
                  type="datetime-local"
                  name="end_time"
                  value={formData.end_time}
                  onChange={handleChange}
                />
              </FormControl>
            </>
          )}
          <FormControl id="is_active">
            <Checkbox
              name="is_active"
              isChecked={formData.is_active}
              onChange={handleChange}
            >
              Active
            </Checkbox>
          </FormControl>
          {/* Error Alert */}
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {/* Success Alert */}
          {success && (
            <Alert status="success">
              <AlertIcon />
              {success}
            </Alert>
          )}
          {/* Submit Button */}
          <Button type="submit" colorScheme="blue">
            Update Discount
          </Button>
          <Button colorScheme="red" onClick={onOpen}>
            Delete Discount
          </Button>
        </VStack>
      </form>

      {/* Confirmation Dialog for Deletion */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Discount
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this discount? This action cannot
              be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                isLoading={loading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EditDiscount;
