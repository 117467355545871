// Benefits.js
import React, { useEffect } from 'react';
import { defaultProps } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { Menu } from "@mantine/core";
import "./Benefits.css";

const sections = [
    { title: "Section 1", value: "section1" },
    { title: "Section 2", value: "section2" },
    { title: "Section 3", value: "section3" },
];

const BenefitsComponent = ({ block, editor, contentRef }) => {
    useEffect(() => {
        if (!block.content?.length) {
            editor.updateBlock(block, {
                type: "benefits",
                props: block.props,
                content: [{ type: "text", text: "" }]
            });
        }
    }, [block, editor]);

    return (
        <div className="benefits-wrapper" data-section={block.props.section}>
            <Menu withinPortal={false}>
                <Menu.Target>
                    <div className="benefits-section" contentEditable={false}>
                        Section {block.props.section.charAt(block.props.section.length - 1)}
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>Select Section</Menu.Label>
                    {sections.map((section) => (
                        <Menu.Item
                            key={section.value}
                            onClick={() =>
                                editor.updateBlock(block, {
                                    type: "benefits",
                                    props: { section: section.value },
                                    content: block.content,
                                })
                            }
                        >
                            {section.title}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
            <div className="benefits-content" ref={contentRef} />
        </div>
    );
};

export const Benefits = createReactBlockSpec(
    {
        type: "benefits",
        propSchema: {
            ...defaultProps,
            section: {
                default: "section1",
                values: ["section1", "section2", "section3"],
            },
        },
        content: "inline",
        containsInlineContent: true,
    },
    {
        render: ({ block, editor, contentRef }) => (
            <BenefitsComponent
                block={block}
                editor={editor}
                contentRef={contentRef}
            />
        ),
    }
);