// chakra imports
import { Icon, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { MdNotifications } from 'react-icons/md';
import React from 'react';

export function ItemContent({ label, description, created_at, onClick }) {
  const textColor = useColorModeValue('navy.700', 'white');
  const dateColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      onClick={onClick}
      cursor="pointer"
      my="10px"
    >
      <Flex
        justify="center"
        align="center"
        borderRadius="16px"
        minH={{ base: '60px', md: '70px' }}
        h={{ base: '60px', md: '70px' }}
        minW={{ base: '60px', md: '70px' }}
        w={{ base: '60px', md: '70px' }}
        me="14px"
        bg="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      >
        <Icon as={MdNotifications} color="white" w={6} h={6} />
      </Flex>
      <Flex flexDirection="column">
        <Text
          fontWeight="bold"
          color={textColor}
          fontSize={{ base: 'md', md: 'md' }}
        >
          {label}
        </Text>
        <Text fontSize={{ base: 'sm', md: 'sm' }} color={textColor}>
          {description}
        </Text>
        <Text fontSize="xs" color={dateColor} mt="5px">
          {new Date(created_at).toLocaleString()}
        </Text>
      </Flex>
    </Flex>
  );
}
