// CookieConsentComponent.js
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {
    run as runCookieConsent, 
    hide as hideCookieConsent, 
    show as showCookieConsent, 
    acceptCategory, 
    validConsent,
} from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import getConfig from './CookieConsentConfig';
import cookie from 'cookie';
import {Box, useColorMode} from '@chakra-ui/react';

const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[name];
};

const CookieConsentComponent = () => {
    const location = useLocation();
    const {colorMode} = useColorMode();

    // Update the cc--darkmode class based on colorMode
    useEffect(() => {
        if (colorMode === 'dark') {
            document.documentElement.classList.add('cc--darkmode');
        } else {
            document.documentElement.classList.remove('cc--darkmode');
        }
    }, [colorMode]);

    useEffect(() => {
        let hideTimeout;
    
        const initializeCookieConsent = () => {
            const userToken = getCookie('token');
            const companyToken = getCookie('x-company');
            const isFlagEnabled = true; // Set the boolean flag as needed
            const config = getConfig(undefined, isFlagEnabled);
    
            // Add tokens to the config if needed
            config.userToken = userToken;
            config.companyToken = companyToken;
    
            // Initialize CookieConsent
            runCookieConsent(config);
    
            // Automatically accept necessary cookies
            acceptCategory(['necessary']);
    
            // Store the instance on window object
            window.cc = {
                hide: hideCookieConsent,
                show: showCookieConsent,
            };
        };
    
        if (!window.cc) {
            initializeCookieConsent();
        }
    
        if (validConsent()) {
            hideCookieConsent();
        } else if (location.pathname === '/auth/login') {
            showCookieConsent();
    
            // Set a timeout to hide the modal after 10 seconds
            hideTimeout = setTimeout(() => {
                hideCookieConsent();
            }, 10000);
        }
    
        // Cleanup function to clear timeout
        return () => {
            if (hideTimeout) {
                clearTimeout(hideTimeout);
            }
        };
    }, [location.pathname]);
    

    return <Box/>;
};

export default CookieConsentComponent;
