import React from 'react';
import { HStack, Text, IconButton, Icon } from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { FiInfo } from 'react-icons/fi';
import { FaAnchor, FaRegArrowAltCircleDown } from 'react-icons/fa';
import { FaArrowsSplitUpAndLeft } from 'react-icons/fa6';

export default function FlowItem({ type, content, onDelete, onEdit }) {
  const getIcon = (type) => {
    switch (type) {
      case 'IF_AND':
        return FaArrowsSplitUpAndLeft;
      case 'IF_OR':
        return FaAnchor;
      case 'THEN':
        return FaRegArrowAltCircleDown;
      default:
        return FiInfo;
    }
  };

  return (
    <HStack
      w="100%"
      bg={type === 'THEN' ? '#6789FF' : '#FFD600'}
      p={2}
      borderRadius="md"
      spacing={1}
    >
      <Icon as={getIcon(type)} color="#0f0f0f" boxSize={6} m={1} />
      <Text flex={1} fontFamily="sans-serif" fontWeight="500">
        {content}
      </Text>
      <IconButton
        icon={<EditIcon />}
        size="lg"
        variant="ghost"
        boxSize={8}
        minW={8}
        minH={8}
        p={0}
        onClick={onEdit}
      />
      <IconButton
        icon={<DeleteIcon />}
        size="lg"
        variant="ghost"
        boxSize={8}
        minW={8}
        minH={8}
        p={0}
        onClick={onDelete}
      />
    </HStack>
  );
}
