import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';

const TermsOfService = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const bgColor = useColorModeValue('white', 'navy.800');
  const linkColor = useColorModeValue('blue.600', 'blue.300');

  return (
    <Box pt={{ sm: '125px', lg: '75px' }} pb="20px">
      <Container maxW="container.lg" bg={bgColor} borderRadius="lg" p={8} shadow="sm">
        <VStack spacing={6} align="stretch">
          <Heading as="h1" size="xl" color={textColor}>
            Terms of Service
          </Heading>

          <Text color={textColor}>
            Last updated: October 29, 2024
          </Text>

          <Box>
            <Heading as="h2" size="md" color={textColor} mb={2}>
              Company Information
            </Heading>
            <Text color={textColor}>
              These Terms of Service ("Terms") govern your use of services provided by Alder Solutions OÜ
              ("we", "us", "our", "Company"), registered in Estonia.
            </Text>
            <VStack spacing={2} pl={4} mt={2} align="stretch">
              <Text color={textColor}>Company: Alder Solutions OÜ</Text>
              <Text color={textColor}>Location: Tallinn, Estonia</Text>
              <Text color={textColor}>Contact: <Link color={linkColor} href="mailto:info@perxify.com">info@perxify.com</Link></Text>
            </VStack>
          </Box>

          <Box>
            <Heading as="h2" size="md" color={textColor} mb={2}>
              Governing Law
            </Heading>
            <Text color={textColor}>
              These Terms are governed by Estonian law. Any disputes arising from or in connection with
              these Terms shall be subject to the exclusive jurisdiction of the courts of Estonia. These
              Terms comply with applicable EU regulations, including but not limited to GDPR, eCommerce
              Directive, and Consumer Rights Directive.
            </Text>
          </Box>

          [Previous sections remain the same...]

          <Box>
            <Heading as="h2" size="md" color={textColor} mb={2}>
              9. Contact Information
            </Heading>
            <Text color={textColor}>
              For any questions regarding these Terms, or to exercise your rights under these Terms,
              please contact us at <Link color={linkColor} href="mailto:info@perxify.com">info@perxify.com</Link>.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="md" color={textColor} mb={2}>
              10. Dispute Resolution
            </Heading>
            <Text color={textColor}>
              Any disputes arising from these Terms will be resolved:
            </Text>
            <VStack spacing={2} pl={4} mt={2} align="stretch">
              <Text color={textColor}>• First through amicable negotiations</Text>
              <Text color={textColor}>• If negotiations fail, through the courts of Estonia</Text>
              <Text color={textColor}>• For EU consumers, through the EU Online Dispute Resolution platform</Text>
              <Text color={textColor}>• For data protection matters, through the Estonian Data Protection Inspectorate</Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default TermsOfService;