import apiClient from 'api/axios';

/**
 * Retrieves a translation for a given key and optional language
 * @param {string} translation_key - The key of the translation to retrieve
 * @param {string|null} [language=null] - The language code (e.g. 'en', 'es'). If null, returns all languages
 * @returns {Promise<string|Object|null>} Translation value(s) or null if not found
 * @throws {Error} If the API request fails
 */
export async function getTranslation(translation_key, language = null) {
    try {
        const endpoint = language
            ? `/translation/${translation_key}/${language}`
            : `/translation/${translation_key}`;

        const response = await apiClient.get(endpoint);
        return response.data;
    } catch (error) {
        console.error('Error while fetching translation:', error);
        throw error;
    }
}

/**
 * Sets or updates a translation value for a specific key and language
 * @param {string} translation_key - The key of the translation to set
 * @param {string} translation_value - The translation text
 * @param {string} language - The language code (e.g. 'en', 'es')
 * @returns {Promise<{success: boolean}>} Object indicating success
 * @throws {Error} If required parameters are missing or API request fails
 */
export async function setTranslation(translation_key, translation_value, language) {
    try {
        if (!translation_key || !translation_value || !language) {
            throw new Error('Missing required parameters');
        }

        const response = await apiClient.put(
            `/translation/${translation_key}/${language}`,
            { translation_value }
        );
        return response.data;
    } catch (error) {
        console.error('Error while setting translation:', error);
        throw error;
    }
}

/**
 * Deletes a translation by its ID
 * @param {string|number} translationId - The ID of the translation to delete
 * @returns {Promise<{success: boolean}>} Object indicating success
 * @throws {Error} If translation ID is missing or API request fails
 */
export async function deleteTranslation(translationId) {
    try {
        if (!translationId) {
            throw new Error('Translation ID is required');
        }

        const response = await apiClient.delete(`/translation/${translationId}`);
        return response.data;
    } catch (error) {
        console.error('Error while deleting translation:', error);
        throw error;
    }
}
