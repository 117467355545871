// src/hooks/useLoyaltyPrograms.js

import useFetch from './useFetch';
import {getLoyaltyPrograms} from '../api/product';

const useLoyaltyPrograms = () => {
    const {data, loading, error} = useFetch(getLoyaltyPrograms, []);

    return {loyaltyPrograms: data || [], loading, error};
};

export default useLoyaltyPrograms;
