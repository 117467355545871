// src/api/orders.js
import apiClient from './axios';

/**
 * Fetches orders based on provided filters.
 *
 * @param {Object} filters - The filters to apply (e.g., product_type, status, date_range, etc.).
 * @returns {Promise<Object>} - A promise that resolves to the orders data.
 */
export const getOrders = async (filters = {}) => {
  try {
    const response = await apiClient.get('/orders/purchases', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const getOrderDetails = async (orderUuid) => {
  try {
    const response = await apiClient.get(`/orders/${orderUuid}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};
