// src/hooks/useDiscounts.js

import { useState, useEffect } from 'react';
import { getProductDiscounts } from '../api/discount';

const useDiscounts = (filters) => {
  const [discounts, setDiscounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDiscounts = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getProductDiscounts(filters);
        console.log('API Response:', response);

        let flatDiscounts = [];
        let total = 0;

        if (response.data && typeof response.total === 'number') {
          // Expected response format
          flatDiscounts = response.data;
          total = response.total;
        } else if (Array.isArray(response)) {
          // Handle case when response is an array (no discounts)
          flatDiscounts = response;
          total = response.length;
        } else {
          console.error('Unexpected API response structure:', response);
          setError('Unexpected API response structure');
          return;
        }

        // Proceed with processing flatDiscounts
        const discountMap = {};
        const roots = [];

        flatDiscounts.forEach((discount) => {
          discountMap[discount.uuid] = { ...discount, children: [] };
        });

        flatDiscounts.forEach((discount) => {
          if (discount.parent_uuid && discountMap[discount.parent_uuid]) {
            discountMap[discount.parent_uuid].children.push(
              discountMap[discount.uuid],
            );
          } else {
            roots.push(discountMap[discount.uuid]);
          }
        });

        setDiscounts(roots);
        setTotal(total);
      } catch (err) {
        console.error('Error fetching discounts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDiscounts();
  }, [filters]);

  return { discounts, total, loading, error };
};

export default useDiscounts;
