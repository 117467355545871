// InputField.js (Default component)
import {
  Flex,
  FormLabel,
  Input,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

export default function InputField(props) {
  const { id, label, extra, placeholder, type, mb, variant, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Flex direction="column" mb={mb ? mb : '30px'}>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
        {extra && (
          <Text fontSize="sm" fontWeight="400" ms="2px">
            {extra}
          </Text>
        )}
      </FormLabel>
      {variant === 'textarea' ? (
        <Textarea
          {...rest}
          id={id}
          placeholder={placeholder}
          fontWeight="500"
          variant="filled" // Ensures consistency with Input variant
          _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
          h="120px"
          borderColor={borderColor}
          _focus={{
            borderColor: 'blue.500', // Customize as needed
            boxShadow: 'outline',
          }}
        />
      ) : (
        <Input
          {...rest}
          type={type}
          id={id}
          fontWeight="500"
          variant="filled" // Changed to 'filled' for consistency
          placeholder={placeholder}
          _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
          h="44px"
          borderColor={borderColor}
          _focus={{
            borderColor: 'blue.500', // Customize as needed
            boxShadow: 'outline',
          }}
        />
      )}
    </Flex>
  );
}
