import {
  Box,
  Checkbox,
  Text,
  Icon,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaExclamationCircle } from 'react-icons/fa';

const ProductCheckboxCard = ({ product, isChecked, onChange }) => {
  console.log('Rendering ProductCheckboxCard for product:', product);

  const borderColor = useColorModeValue(
    isChecked ? 'blue.500' : 'gray.200',
    isChecked ? 'blue.300' : 'gray.600',
  );

  const handleCardClick = () => {
    console.log('Card clicked for product:', product.uuid);
    onChange(product.uuid);
  };

  const isSoldOut =
    product.is_sold_out || product.quantity === 0 || product.quantity === null;

  const calculateSalePrice = () => {
    let salePrice = product.price;

    if (product.discounts && product.discounts.length > 0) {
      // Assuming you want to apply the first active discount
      const now = new Date();
      const activeDiscount = product.discounts.find((discount) => {
        const startTime = discount.start_time
          ? new Date(discount.start_time)
          : null;
        const endTime = discount.end_time ? new Date(discount.end_time) : null;

        return (
          discount.is_active &&
          (!startTime || now >= startTime) &&
          (!endTime || now <= endTime)
        );
      });

      if (activeDiscount) {
        if (activeDiscount.discount_price) {
          salePrice = product.price - activeDiscount.discount_price;
          console.log('Using discount price:', activeDiscount.discount_price);
        } else if (activeDiscount.discount_percentage) {
          salePrice =
            product.price * (1 - activeDiscount.discount_percentage / 100);
          console.log(
            'Using discount percentage:',
            activeDiscount.discount_percentage,
          );
        }
      }
    }

    console.log('Calculated sale price for product:', product.uuid, salePrice);
    return salePrice;
  };

  const salePrice = calculateSalePrice();

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      borderColor={borderColor}
      p={4}
      cursor="pointer"
      onClick={handleCardClick}
    >
      <Flex alignItems="center">
        <Checkbox
          isChecked={isChecked}
          onChange={(e) => {
            e.stopPropagation();
            console.log(
              'Checkbox changed for product:',
              product.uuid,
              'isChecked:',
              e.target.checked,
            );
            onChange(product.uuid);
          }}
          mr={2}
        />
        <Text fontWeight="bold">{product.name}</Text>
      </Flex>
      {product.price !== undefined && (
        <Text>Regular Price: ${product.price.toFixed(2)}</Text>
      )}
      {salePrice !== undefined && salePrice !== product.price && (
        <Text color="green.500">Sale Price: ${salePrice.toFixed(2)}</Text>
      )}
      {!isSoldOut && <Text>Quantity: {product.quantity}</Text>}
      {isSoldOut && (
        <Flex alignItems="center" color="red.500" mt={2}>
          <Icon as={FaExclamationCircle} mr={1} />
          <Text>Sold Out</Text>
        </Flex>
      )}
    </Box>
  );
};

export default ProductCheckboxCard;
