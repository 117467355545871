import apiClient from './axios';

export const getSales = async (properties) => {
  try {
    const response = await apiClient.get('/dashboard/sales', {
      params: properties,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching sales:', error);
    throw error;
  }
};

export const getRegisteredUsers = async (properties) => {
  try {
    const response = await apiClient.get('/dashboard/users', {
      params: properties,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching registered users:', error);
    throw error;
  }
};

export const getLoyaltyProgramsSold = async (properties) => {
  try {
    const response = await apiClient.get('/dashboard/loyalty-programs', {
      params: properties,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching loyalty programs sold:', error);
    throw error;
  }
};

export const getLatestLoyaltyPrograms = async (properties) => {
  try {
    const response = await apiClient.get('/dashboard/latest-loyalty-programs', {
      params: properties,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching latest loyalty programs:', error);
    throw error;
  }
};

export const getLatestProducts = async (properties) => {
  try {
    const response = await apiClient.get('/dashboard/latest-products', {
      params: properties,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching latest products:', error);
    throw error;
  }
};

export const getCreditBalance = async (properties) => {
  try {
    const response = await apiClient.get('/dashboard/credit-balance', {
      params: properties,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching credit balance:', error);
    throw error;
  }
};
