import apiClient from '../api/axios'; 
import Cookies from 'js-cookie'; 

export const login = async (email, password) => {
  const response = await apiClient.post('/auth/login', { 
    email,
    password,
  });

  const { token } = response.data;

  if (token) {
    // Store the token in cookies
    Cookies.set('token', token, {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
      expires: 7, // 1 week
    });
  }

  return token;
};
