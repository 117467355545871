import apiClient from '../api/axios';

export const googleSignIn = () => {
  const successUrl = encodeURIComponent(
    window.location.origin + '/auth/google-auth-callback',
  );
  const failureUrl = encodeURIComponent(
    window.location.origin + '/auth/failure',
  );
  const googleSignInUrl = `${apiClient.defaults.baseURL}/auth/google?successUrl=${successUrl}&failureUrl=${failureUrl}`;

  window.location.href = googleSignInUrl;
};