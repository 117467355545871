// src/utils/authCookies.js
import Cookies from 'js-cookie';

export const setAuthCookies = (token, company = null) => {
  Cookies.set('token', token, {
    secure: process.env.NODE_ENV !== 'development',
    sameSite: 'Strict',
    expires: 7, // 1 week
  });

  if (company) {
    Cookies.set('x-company', company.x_company_token, {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
      expires: 7, // 1 week
    });
  }
};
