// src/components/orders/OrderDetail.jsx
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Button,
  useToast,
  Text,
  Badge,
  Spinner,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import { getOrderDetails } from '../../api/order';
import { getProductDetails } from '../../api/product'; // Import getProductDetails
import { getUserDetails } from '../../api/user'; // Import getUserDetails
import { format } from 'date-fns';

export default function OrderDetail() {
  const { uuid } = useParams(); // Get the order ID from the URL
  const navigate = useNavigate();
  const toast = useToast();

  const [orderDetails, setOrderDetails] = useState(null);
  const [productImage, setProductImage] = useState(null); // State for product image
  const [userDetails, setUserDetails] = useState(null); // State for user details
  const [loading, setLoading] = useState(true);

  // Fetch the order details
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const order = await getOrderDetails(uuid);
        setOrderDetails(order);

        // Fetch product details to get the image URL
        const product = await getProductDetails(order.product_uuid);
        setProductImage(product.images[0]?.image_url || null);

        // Fetch user details to get the user's name
        const user = await getUserDetails(order.user_uuid);
        setUserDetails(user);
      } catch (error) {
        console.error('Error fetching order, product, or user details:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch order, product, or user details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [uuid, toast]);

  if (loading) {
    return (
      <Flex justify="center" align="center" h="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (!orderDetails) {
    return (
      <Text textAlign="center" mt="20px">
        No order details found.
      </Text>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        columns={{ sm: 1, xl: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Left Column */}
        <Flex direction="column">
          {/* Order Info */}
          <Card p="30px">
            <Flex direction="column">
              <Text fontSize="2xl" fontWeight="bold" mb="20px">
                Order Details
              </Text>
              <Text mb="10px">
                <strong>Order ID:</strong> {orderDetails.id}
              </Text>
              <Text mb="10px">
                <strong>User:</strong>{' '}
                {userDetails
                  ? userDetails.full_name || userDetails.email || 'Unknown'
                  : 'Loading...'}
              </Text>
              <Text mb="10px">
                <strong>Product:</strong> {orderDetails.product_name}
              </Text>
              <Text mb="10px">
                <strong>Purchase Date:</strong>{' '}
                {format(
                  new Date(orderDetails.purchase_date),
                  'MMMM dd, yyyy, h:mm a',
                )}
              </Text>
              <Text mb="10px">
                <strong>Price:</strong> €
                {orderDetails.purchase_price.toFixed(2)}
              </Text>
              <Text mb="10px">
                <strong>Status:</strong>
                <Badge
                  colorScheme={
                    orderDetails.status === 'complete'
                      ? 'green'
                      : orderDetails.status === 'paid'
                        ? 'purple'
                        : orderDetails.status === 'processing'
                          ? 'yellow'
                          : orderDetails.status === 'initiated'
                            ? 'cyan'
                            : orderDetails.status === 'failed'
                              ? 'red'
                              : 'gray'
                  }
                >
                  {orderDetails.status
                    ? orderDetails.status.charAt(0).toUpperCase() +
                      orderDetails.status.slice(1)
                    : 'Unknown'}
                </Badge>
              </Text>
            </Flex>
          </Card>
        </Flex>
        {/* Right Column */}
        <Flex direction="column">
          {/* Product Image */}
          <Card mb="20px">
            <Image
              borderRadius="20px"
              h={{ base: 'auto', xl: '396px', '2xl': 'auto' }}
              src={productImage}
              alt={orderDetails.product_name || 'Product Image'}
              fallbackSrc="https://via.placeholder.com/396" // Optional: Placeholder image
            />
          </Card>
          {/* Back Button */}
          <Button
            mt="20px"
            colorScheme="blue"
            onClick={() => navigate('/admin/orders')}
          >
            Back to Orders
          </Button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
