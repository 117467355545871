// src/hooks/useFetch.js

import { useState, useEffect } from 'react';

const useFetch = (asyncFunction, args = [], dependencies = []) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted components

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const result = await asyncFunction(...args);
        if (isMounted) {
          setData(result);
        }
      } catch (err) {
        console.error('Error in useFetch:', err);
        if (isMounted) {
          setError(err.message || 'An error occurred.');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies); // Trigger fetch when dependencies change

  return { data, loading, error };
};

export default useFetch;
