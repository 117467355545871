import React, { useState, useEffect, useCallback } from 'react';
import {
  useToast,
  useDisclosure,
  Box,
  Flex,
  Text,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Select,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Card,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { FaCheck, FaBan } from 'react-icons/fa';
import apiClient from 'api/axios';

export default function CompanyUsers() {
  const [users, setUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newUser, setNewUser] = useState({
    full_name: '',
    email: '',
    role_id: '',
    is_verified: false,
  });
  const [companyUuid, setCompanyUuid] = useState('');
  const toast = useToast();

  const fetchUsers = async () => {
    try {
      const companyUsers = await apiClient.get(`/company/users`);
      const company = await apiClient.get(`/company`);
      setCompanyUuid(company.data.uuid);
      setUsers(companyUsers.data);
    } catch (error) {
      console.error('Error fetching users', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAddUser = async () => {
    try {
      await apiClient.post(`/user`, {
        full_name: newUser.full_name,
        email: newUser.email,
        role_id: newUser.role_id,
        is_verified: newUser.is_verified,
      });

      fetchUsers();
      onClose();
      toast({
        title: 'User added.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setNewUser({
        full_name: '',
        email: '',
        role_id: '',
        is_verified: false,
      });
    } catch (error) {
      console.error('Error adding user', error);
      toast({
        title: 'Error adding user.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRoleChange = async (uuid, newRole) => {
    try {
      await apiClient.put(`/user/${uuid}`, {
        role: newRole,
      });
      fetchUsers();
      toast({
        title: 'Role updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating role', error);
      toast({
        title: 'Error updating role.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteUser = async (uuid) => {
    try {
      await apiClient.put(`/user-roles/${uuid}`, {
        company_uuid: companyUuid,
      });
      setUsers((prevUsers) => prevUsers.filter((user) => user.uuid !== uuid));
      toast({
        title: 'User deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting user', error);
      toast({
        title: 'Error deleting user.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Card mb="20px" w="100%">
      <Flex
        alignItems="center"
        direction={{ sm: 'column', md: 'row' }}
        justifyContent="space-between"
        p={5}
      >
        <Text fontSize="2xl" fontWeight="bold">
          Company Users
        </Text>
        <Button colorScheme="blue" onClick={onOpen}>
          Add New User
        </Button>
      </Flex>
      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Full Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Verified</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.uuid}>
                <Td>{user.full_name}</Td>
                <Td>{user.email}</Td>
                <Td>
                  <Select
                    value={user.role}
                    onChange={(e) =>
                      handleRoleChange(user.uuid, e.target.value)
                    }
                  >
                    <option value="COMPANY_ADMIN">Admin</option>
                  </Select>
                </Td>
                <Td>
                  <Flex justifyContent="center">
                    {user.is_verified ? (
                      <FaCheck color="green" />
                    ) : (
                      <FaBan color="red" />
                    )}
                  </Flex>
                </Td>
                <Td>
                  <IconButton
                    colorScheme="red"
                    aria-label="Delete user"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteUser(user.uuid)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              <Input
                placeholder="Full Name"
                mb={3}
                value={newUser.full_name}
                onChange={(e) =>
                  setNewUser({ ...newUser, full_name: e.target.value })
                }
              />
              <Input
                placeholder="Email"
                mb={3}
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
              <Select
                placeholder="Select Role"
                value={newUser.role_id}
                onChange={(e) =>
                  setNewUser({ ...newUser, role_id: e.target.value })
                }
              >
                <option value="2">Admin</option>
              </Select>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddUser}>
              Add User
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
