// src/hooks/useProducts.js

import { useMemo } from 'react';
import useFetch from './useFetch';
import { getProducts } from '../api/product';
import { useEffect } from 'react';

// src/hooks/useProducts.js

const useProducts = (filters = {}) => {
  const { data, loading, error } = useFetch(
    () => getProducts(filters),
    [JSON.stringify(filters)],
  );
  return { products: data || [], loading, error };
};

export default useProducts;
