import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  useToast,
  Card,
  Textarea,
  Image,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  SimpleGrid,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { languages as countryLanguages } from 'countries-list';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { getCompany, updateCompany, uploadCompanyImage } from 'api/company';
import Dropzone from '../../components/products/Dropzone';
import HeroImage from 'assets/img/company/hero.svg';
import LogoImage from 'assets/img/company/logo.svg';
import HeaderImage from 'assets/img/company/header.svg';

export default function GeneralSettings() {
  const [companyName, setCompanyName] = useState('');
  const [description, setDescription] = useState('');
  const [programTitle, setProgramTitle] = useState('');
  const [companyUuid, setCompanyUuid] = useState('');
  const [logo, setLogo] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [heroImage, setHeroImage] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [languageToDelete, setLanguageToDelete] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = useRef();
  const toast = useToast();

  const allLanguages = Object.entries(countryLanguages).map(([code, data]) => {
    return {
      code: code,
      name: data.name,
      nativeName: data.native,
    };
  });

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const company = await getCompany();
      setCompanyName(company.name);
      setDescription(company.description);
      setCompanyUuid(company.uuid);
      setProgramTitle(company.program_title);
      setLogo(company.logo_url || null);
      setHeaderImage(company.header_image_url || null);
      setHeroImage(company.hero_image_url || null);

      if (company.languages) {
        const languageCodes = company.languages;
        const languageObjects = languageCodes.map((code) => ({
          code,
          name: countryLanguages[code].name,
          nativeName: countryLanguages[code].native,
        }));
        setLanguages(languageObjects);
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
      toast({
        title: 'Error fetching company details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  const handleDeleteLanguage = (lang) => {
    setLanguageToDelete(lang);
    onOpen();
  };

  const confirmDelete = () => {
    setLanguages((langs) =>
      langs.filter((l) => l.code !== languageToDelete.code),
    );
    onClose();
  };

  const handleLogoChange = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setLogo(acceptedFiles[0]);
    }
  };

  const handleHeaderImageChange = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setHeaderImage(acceptedFiles[0]);
    }
  };

  const handleHeroImageChange = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setHeroImage(acceptedFiles[0]);
    }
  };

  const handleSave = async () => {
    try {
      const languageCodes = languages.map((lang) => lang.code);
      await updateCompany(companyUuid, {
        name: companyName,
        description,
        program_title: programTitle,
        languages: Object.assign({}, languageCodes),
      });

      if (logo && typeof logo !== 'string') {
        await uploadCompanyImage('logo', logo);
      }

      if (headerImage && typeof headerImage !== 'string') {
        await uploadCompanyImage('header', headerImage);
      }

      if (heroImage && typeof heroImage !== 'string') {
        await uploadCompanyImage('hero', heroImage);
      }

      toast({
        title: 'Company details updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating company details:', error);
      toast({
        title: 'Error updating company details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Card mb="20px" w="100%">
      <Flex alignItems="center" direction="column" p={5}>
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
        >
          <Text fontSize="2xl" fontWeight="bold" mb={5}>
            General Settings
          </Text>
          <Button ml="auto" size="md" colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </Flex>
        <Box width="100%" mb={3}>
          <Text mb={1}>Company Name</Text>
          <Input
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Enter company name"
          />
        </Box>
        <Box width="100%" mb={3}>
          <Text mb={1}>Description</Text>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter company description"
          />
        </Box>
        <Box width="100%" mb={3}>
          <Text mb={1}>Program Title</Text>
          <Input
            value={programTitle}
            onChange={(e) => setProgramTitle(e.target.value)}
            placeholder="Enter program title"
          />
        </Box>
        <Box width="100%" mb={3}>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontWeight="bold">Languages</Text>
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => setIsLanguageModalOpen(true)}
            >
              Add Language
            </Button>
          </Flex>

          <Flex wrap="wrap" gap={2}>
            {languages.map((lang) => (
              <Tag
                key={lang.code}
                size="lg"
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
              >
                <TagLabel>{lang.name}</TagLabel>
                <TagCloseButton onClick={() => handleDeleteLanguage(lang)} />
              </Tag>
            ))}
          </Flex>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Delete Language</AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to remove {languageToDelete?.name}?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" ml={3} onClick={confirmDelete}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <Modal
            isOpen={isLanguageModalOpen}
            onClose={() => setIsLanguageModalOpen(false)}
            size="xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Select Languages</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Search languages..."
                  mb={4}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <SimpleGrid
                  columns={2}
                  spacing={4}
                  maxH="60vh"
                  overflowY="auto"
                >
                  {allLanguages
                    .filter(
                      (lang) =>
                        lang.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        lang.nativeName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        lang.code
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()),
                    )
                    .map((lang) => (
                      <Button
                        key={lang.code}
                        onClick={() => {
                          setLanguages((prev) => [...prev, lang]);
                          setIsLanguageModalOpen(false);
                        }}
                        isDisabled={languages.some((l) => l.code === lang.code)}
                        justifyContent="flex-start"
                      >
                        {lang.name} ({lang.nativeName})
                      </Button>
                    ))}
                </SimpleGrid>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          width="100%"
          mb={3}
          justify="space-between"
          mt={3}
          gap={4}
        >
          {[
            {
              label: 'Logo',
              image: logo,
              defaultImage: LogoImage,
              handleChange: handleLogoChange,
            },
            {
              label: 'Header Image',
              image: headerImage,
              defaultImage: HeaderImage,
              handleChange: handleHeaderImageChange,
            },
            {
              label: 'Hero Image',
              image: heroImage,
              defaultImage: HeroImage,
              handleChange: handleHeroImageChange,
            },
          ].map(({ label, image, defaultImage, handleChange }, index) => (
            <Card key={index} width={{ base: '100%', md: '33%' }} p={4}>
              <Text mb={2} fontWeight="bold" align="center">
                {label}
              </Text>
              <Flex
                direction="column"
                alignItems="center"
                justify="space-between"
                height="100%"
              >
                <a
                  href={
                    image && typeof image === 'object'
                      ? URL.createObjectURL(image)
                      : image || defaultImage
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={
                      image && typeof image === 'object'
                        ? URL.createObjectURL(image)
                        : image || defaultImage
                    }
                    maxH={200}
                    alt={label}
                    mb={4}
                  />
                </a>
                <Dropzone onDrop={handleChange}>
                  <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    h="70px"
                    w="100%"
                  >
                    <Icon
                      as={MdOutlineCloudUpload}
                      w="24px"
                      h="24px"
                      mb="8px"
                    />
                    <Text fontSize="sm" fontWeight="bold">
                      Upload {label}
                    </Text>
                  </Flex>
                </Dropzone>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Flex>
    </Card>
  );
}
