import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

export default function LoyaltyLevelModalAlert({
  isArchiveDialogOpen,
  setIsArchiveDialogOpen,
  levelModalData,
  handleArchive,
  cancelRef,
}) {
  return (
    <AlertDialog
      isOpen={isArchiveDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => setIsArchiveDialogOpen(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {levelModalData.is_archived
              ? 'Unarchive Loyalty Level'
              : 'Archive Loyalty Level'}
          </AlertDialogHeader>
          <AlertDialogBody>
            {levelModalData.is_archived
              ? 'Are you sure you want to unarchive this loyalty level?'
              : 'Are you sure you want to archive this loyalty level?'}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => setIsArchiveDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleArchive} ml={3}>
              {levelModalData.is_archived ? 'Unarchive' : 'Archive'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
