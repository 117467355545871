import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, Button, FormControl, FormLabel, Input, Textarea, Text,
} from '@chakra-ui/react';
import { createCompany } from 'api/company';


export default function CompanyAdd() {
    const [name, setName] = useState('');
    const [programTitle, setProgramTitle] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            await createCompany({
                name, program_title: programTitle, description,
            });
            navigate('/admin/superadmin/companies');
        } catch (error) {
            setErrorMessage('Error creating company');
            console.error('Error creating company', error);
        } finally {
            setLoading(false);
        }
    };


    return (<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <form onSubmit={handleSubmit}>
            <FormControl id="name" isRequired>
                <FormLabel>Company Name</FormLabel>
                <Input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>
            <FormControl id="programTitle" isRequired mt={4}>
                <FormLabel>Program Title</FormLabel>
                <Input
                    type="text"
                    value={programTitle}
                    onChange={(e) => setProgramTitle(e.target.value)}
                />
            </FormControl>
            <FormControl id="description" isRequired mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </FormControl>
            {errorMessage && (<Text color="red.500" mt={4}>
                {errorMessage}
            </Text>)}
            <Button
                mt={4}
                colorScheme="blue"
                isLoading={loading}
                type="submit"
            >
                Add Company
            </Button>
        </form>
    </Box>);
}