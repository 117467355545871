import React, { useState, useEffect, useMemo } from 'react';
import {
  Flex,
  Select,
  Spinner,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Input,
} from '@chakra-ui/react';

import { SearchIcon, AddIcon } from '@chakra-ui/icons';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { getOrders } from '../../api/order';
import Pagination from '../products/pagination';
import '../../assets/css/OrdersOverview.css';
import { format } from 'date-fns';

export default function OrdersOverview() {
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState('');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sortBy, setSortBy] = useState([{ id: 'purchase_date', desc: true }]);

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const filters = {
          product_type: 'regular',
          status: statusFilter || undefined,
          start_date: dateRange.start || undefined,
          end_date: dateRange.end || undefined,
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          sort_by: sortBy[0]?.id || 'purchase_date',
          sort_order: sortBy[0]?.desc ? 'desc' : 'asc',
        };

        const ordersData = await getOrders(filters);
        setOrders(ordersData.data);
        setTotalOrders(ordersData.total);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, [
    statusFilter,
    dateRange,
    pagination.pageIndex,
    pagination.pageSize,
    sortBy,
  ]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'id',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            ORDER ID
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('user_full_name', {
        id: 'user',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            USER
          </Text>
        ),
        cell: (info) => (
          <Flex direction="column">
            <Text color={textColor} fontSize="md" fontWeight="500">
              {info.getValue()} {}
            </Text>
            <Text color="gray.500" fontSize="sm">
              {info.row.original.user_primary_email} {}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('product_name', {
        id: 'product',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            PRODUCT
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('purchase_date', {
        id: 'purchase_date',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            PURCHASE DATE
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            {format(new Date(info.getValue()), 'MMM d, yyyy')}
          </Text>
        ),
      }),
      columnHelper.accessor('purchase_price', {
        id: 'purchase_price',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            PRICE
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="md" fontWeight="500">
            €{info.getValue().toFixed(2)}
          </Text>
        ),
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            STATUS
          </Text>
        ),
        cell: (info) => {
          const status = info.getValue();
          return (
            <Badge
              colorScheme={
                status === 'complete'
                  ? 'green'
                  : status === 'paid'
                    ? 'purple'
                    : status === 'processing'
                      ? 'yellow'
                      : status === 'initiated'
                        ? 'cyan'
                        : status === 'failed' // Added
                          ? 'red'
                          : 'gray'
              }
            >
              {status
                ? status.charAt(0).toUpperCase() + status.slice(1)
                : 'Unknown'}
            </Badge>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
            ACTIONS
          </Text>
        ),
        cell: (info) => (
          <Flex>
            <Text
              w="16px"
              h="16px"
              as={MdEdit}
              cursor="pointer"
              color={brandColor}
              onClick={() =>
                navigate(`/admin/orders/${info.row.original.uuid}`)
              }
            ></Text>
          </Flex>
        ),
      }),
    ],
    [textColor, navigate, brandColor],
  );

  const table = useReactTable({
    data: orders,
    columns,
    state: {
      globalFilter,
      columnFilters,
      pagination,
      sorting: sortBy,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSortBy,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    pageCount: Math.ceil(totalOrders / pagination.pageSize),
  });

  const filteredOrders = useMemo(() => {
    let filtered = orders;

    if (globalFilter) {
      const searchLower = globalFilter.toLowerCase();
      filtered = filtered.filter(
        (order) =>
          order.user_full_name.toLowerCase().includes(searchLower) ||
          order.user_primary_email.toLowerCase().includes(searchLower) ||
          order.product_name.toLowerCase().includes(searchLower),
      );
    }

    if (statusFilter && statusFilter !== 'all') {
      filtered = filtered.filter((order) => order.status === statusFilter);
    }

    if (dateRange.start) {
      const startDate = new Date(dateRange.start);
      filtered = filtered.filter(
        (order) => new Date(order.purchase_date) >= startDate,
      );
    }
    if (dateRange.end) {
      const endDate = new Date(dateRange.end);
      filtered = filtered.filter(
        (order) => new Date(order.purchase_date) <= endDate,
      );
    }

    return filtered;
  }, [orders, globalFilter, statusFilter, dateRange]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} px="20px">
      {}
      <Flex
        direction="row"
        mb="20px"
        align="center"
        justifyContent="space-between"
      >
        <Flex direction="row" align="center">
          {}
          <Select
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e.target.value);
              setPagination((prev) => ({ ...prev, pageIndex: 0 }));
            }}
            mr="10px"
            w="200px"
          >
            <option value="all">All Statuses</option>
            <option value="initiated">Initiated</option>
            <option value="processing">Processing</option>
            <option value="paid">Paid</option>
            <option value="complete">Complete</option>
            <option value="failed">Failed</option> {/* Added */}
          </Select>

          {}
          <Input
            type="date"
            placeholder="Start Date"
            value={dateRange.start}
            onChange={(e) => {
              setDateRange((prev) => ({ ...prev, start: e.target.value }));
              setPagination((prev) => ({ ...prev, pageIndex: 0 }));
            }}
            mr="10px"
            w="150px"
          />
          <Input
            type="date"
            placeholder="End Date"
            value={dateRange.end}
            onChange={(e) => {
              setDateRange((prev) => ({ ...prev, end: e.target.value }));
              setPagination((prev) => ({ ...prev, pageIndex: 0 }));
            }}
            mr="10px"
            w="150px"
          />

          {}
          <Flex align="center" className="search-container">
            <SearchIcon color="gray.500" mr="5px" />
            <Input
              placeholder="Search orders..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              w="250px"
              className="search-input"
            />
          </Flex>
        </Flex>
      </Flex>

      {}
      {loading ? (
        <Flex justify="center" align="center" h="200px">
          <Spinner size="lg" />
        </Flex>
      ) : orders.length > 0 ? (
        <>
          {}
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      borderColor={borderColor}
                      cursor={
                        header.column.getCanSort() ? 'pointer' : 'default'
                      }
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex align="center" justify="space-between">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {}
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? (
                            <Text fontSize="xs">↓</Text>
                          ) : (
                            <Text fontSize="xs">↑</Text>
                          )
                        ) : (
                          header.column.getCanSort() && (
                            <Text fontSize="xs">⇅</Text>
                          )
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} borderColor={borderColor}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>

          {}
          <Flex
            w="100%"
            justify="space-between"
            px="20px"
            pt="10px"
            pb="5px"
            alignItems="center"
          >
            <Text fontSize="sm" color="gray.500" fontWeight="normal">
              Showing {pagination.pageIndex * pagination.pageSize + 1} to{' '}
              {Math.min(
                (pagination.pageIndex + 1) * pagination.pageSize,
                totalOrders,
              )}{' '}
              of {totalOrders} entries
            </Text>

            <Pagination
              pageIndex={pagination.pageIndex}
              pageCount={table.getPageCount()}
              canNextPage={table.getCanNextPage()}
              canPreviousPage={table.getCanPreviousPage()}
              nextPage={table.nextPage}
              previousPage={table.previousPage}
              setPageIndex={table.setPageIndex}
              textColor={textColor}
              borderColor={borderColor}
              brandColor={brandColor}
            />
          </Flex>
        </>
      ) : (
        <Text textAlign="center" mt="20px">
          No orders found.
        </Text>
      )}
    </Flex>
  );
}
