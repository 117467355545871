import apiClient from 'api/axios';
import { getLoggedInUserDetails } from 'api/user';

export async function getUserNotifications() {
  const user = await apiClient.get('/user');
  const userUuid = user.data.uuid;

  try {
    const response = await apiClient.get(`/notifications/${userUuid}`);
    return response.data;
  } catch (error) {
    console.error('Error while fetching notifications', error);
  }
}

export async function markNotificationAsRead(notificationId) {
  const user = await getLoggedInUserDetails();
  try {
    await apiClient.delete(`/notifications/${notificationId}/${user.uuid}`);
  } catch (error) {
    console.error('Error while marking notification as read', error);
  }
}
