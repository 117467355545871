// NewProduct.js
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Icon,
  useToast,
  Image,
  Text,
  Switch,
} from '@chakra-ui/react';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import InputField from '../../components/fields/InputField';
import Dropzone from '../../components/products/Dropzone';
import { MdOutlineCloudUpload } from 'react-icons/md';

// Import the Details component
import Details from '../../components/products/Details';

// Import API functions
import { createProduct, handleImageUpload } from '../../api/product';

export default function NewProduct() {
  const navigate = useNavigate();
  const toast = useToast();

  // Form state variables
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [isFeatured, setIsFeatured] = useState(false);
  const [image, setImage] = useState(null);
  const [quantity, setQuantity] = useState(0); // For managing stock levels

  // Details component state variables
  const [productPrice, setProductPrice] = useState('');

  // Handle image upload via Dropzone
  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      console.log('Accepted file:', file);
      // Validate file type
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        console.error('Invalid file type:', file.type);
        toast({
          title: 'Invalid file type.',
          description: 'Only PNG, JPG, and GIF files are allowed.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      // Validate file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        console.error('File size exceeds limit:', file.size);
        toast({
          title: 'File too large.',
          description: 'Image size should be less than 5MB.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setImage(file);
      console.log('Image set in state:', file);
    } else {
      console.warn('No files were accepted by Dropzone.');
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    const data = {
      name: productName,
      description: productDescription,
      price: Number(productPrice), // Ensure price is a number
      is_featured: isFeatured,
      quantity: quantity, // Include quantity
    };

    console.log('Submitting product data:', data);
    console.log('Current image state:', image);

    try {
      // Post product data
      const response = await createProduct(data);
      console.log('Create Product Response:', response); // Debugging

      const { uuid } = response;
      console.log('Extracted UUID:', uuid); // Debugging

      if (!uuid) {
        throw new Error('Product UUID not returned from the server.');
      }

      // Upload image if available
      if (image) {
        console.log('Uploading Image:', image); // Debugging
        const uploadResponse = await handleImageUpload([image], uuid);
        console.log('Image upload response:', uploadResponse); // Debugging
        console.log('Image uploaded successfully'); // Debugging
      } else {
        console.log('No image to upload.');
      }

      toast({
        title: 'Product created.',
        description: 'The product has been created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/admin/products/product-overview');
    } catch (error) {
      console.error('Error creating product:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
      }
      toast({
        title: 'Error',
        description:
          error.message || 'An error occurred while creating the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10) || 0;
    console.log('Quantity changed to:', newQuantity);
    setQuantity(newQuantity);
  };

  // Cleanup the object URL to avoid memory leaks
  useEffect(() => {
    return () => {
      if (image && typeof image === 'object') {
        URL.revokeObjectURL(image.preview);
      }
    };
  }, [image]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        columns={{ sm: 1, xl: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Left Column */}
        <Flex direction="column">
          {/* Product Image */}
          <Card mb="20px">
            <Image
              borderRadius="20px"
              h={{ base: 'auto', xl: '396px', '2xl': 'auto' }}
              src={
                image && typeof image === 'object'
                  ? URL.createObjectURL(image)
                  : 'https://via.placeholder.com/400x300'
              }
              alt="Product Image Preview"
              onLoad={() => {
                if (image) {
                  console.log('Image loaded successfully:', image.name);
                }
              }}
              onError={(err) => {
                console.error('Error loading image:', err);
                toast({
                  title: 'Image Load Error',
                  description: 'There was an error loading the image preview.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              }}
              fallbackSrc="https://via.placeholder.com/400x300" // Optional: Placeholder image
            />
          </Card>
          {/* Product Info Form */}
          <Card p="30px">
            <Flex direction="column">
              {/* Product Name */}
              <InputField
                mb="20px"
                id="productName"
                label="Product Name"
                placeholder="Product Name"
                value={productName}
                onChange={(e) => {
                  console.log('Product Name changed to:', e.target.value);
                  setProductName(e.target.value);
                }}
                isRequired
              />
              {/* Product Description using InputField with variant="textarea" */}
              <InputField
                mb="20px"
                id="productDescription"
                label="Product Description"
                placeholder="Product Description"
                value={productDescription}
                onChange={(e) => {
                  console.log(
                    'Product Description changed to:',
                    e.target.value,
                  );
                  setProductDescription(e.target.value);
                }}
                variant="textarea" // Specify the variant to render a Textarea
                isRequired
              />
              {/* Quantity Input */}
              <InputField
                mb="20px"
                id="quantity"
                label="Quantity"
                placeholder="Quantity"
                type="number"
                value={quantity}
                onChange={(e) => handleQuantityChange(e.target.value)}
                isRequired
              />
            </Flex>
            {/* Featured Product Switch */}
            <Flex align="center" mt="10px">
              <Text fontWeight="bold" mr="10px">
                Featured Product
              </Text>
              <Switch
                id="isFeatured"
                isChecked={isFeatured}
                onChange={(e) => {
                  console.log('Is Featured toggled to:', e.target.checked);
                  setIsFeatured(e.target.checked);
                }}
              />
            </Flex>
          </Card>
        </Flex>
        {/* Right Column */}
        <Flex direction="column">
          {/* Dropzone component */}
          <Card p="30px" mb="20px">
            <Text fontSize="xl" fontWeight="bold" mb="10px">
              Product Images
            </Text>
            <Dropzone
              onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles)}
            >
              <Flex
                direction="column"
                align="center"
                justify="center"
                h="200px"
              >
                <Icon as={MdOutlineCloudUpload} w="50px" h="50px" mb="8px" />
                <Text fontSize="lg" fontWeight="bold">
                  Drag and drop an image here, or click to select a file
                </Text>
                <Text fontSize="sm" color="gray.500">
                  PNG, JPG, and GIF files are allowed
                </Text>
              </Flex>
            </Dropzone>
            {/* Display selected file details */}
            {image && (
              <Box mt="10px">
                <Text fontSize="sm" color="gray.600">
                  Selected file: {image.name} ({(image.size / 1024).toFixed(2)}{' '}
                  KB)
                </Text>
              </Box>
            )}
          </Card>
          {/* Details component */}
          <Details
            productPrice={productPrice}
            setProductPrice={(value) => {
              console.log('Product Price changed to:', value);
              setProductPrice(value);
            }}
          />
          {/* Submit Button */}
          <Button
            mt="20px"
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={!productName || !productPrice}
          >
            Submit
          </Button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
