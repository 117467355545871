import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Spinner,
  useColorModeValue,
  FormControl,
  Select,
} from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';
import Card from 'components/card/Card';
import { getLoyaltyProgramsSold } from 'api/dashboard';
import {
  format,
  parseISO,
  differenceInDays,
  startOfWeek,
  addWeeks,
  isValid,
} from 'date-fns';

export default function LoyaltyProgramsWidget(props) {
  const { startDate, endDate, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // State variables
  const [loyaltyData, setLoyaltyData] = useState([]);
  const [totalLoyaltyPrograms, setTotalLoyaltyPrograms] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Filter states
  const [groupBy, setGroupBy] = useState('month');

  const [allowedGroupByOptions, setAllowedGroupByOptions] = useState([
    'day',
    'week',
    'month',
    'year',
  ]);

  const [levelsSelections, setLevelSelections] = useState({});

  useEffect(() => {
    const calculateAllowedGroupBy = () => {
      if (startDate && endDate) {
        const start = parseISO(startDate);
        const end = parseISO(endDate);
        const diffDays = differenceInDays(end, start);

        let options = [];
        if (diffDays <= 7) {
          options = ['day'];
        } else if (diffDays <= 31) {
          options = ['day', 'week'];
        } else if (diffDays <= 365) {
          options = ['day', 'week', 'month'];
        } else {
          options = ['day', 'week', 'month', 'year'];
        }

        setAllowedGroupByOptions(options);
      }
    };
    calculateAllowedGroupBy();
  }, [startDate, endDate]);

  useEffect(() => {
    // Second effect: validate groupBy
    if (
      allowedGroupByOptions.length > 0 &&
      !allowedGroupByOptions.includes(groupBy)
    ) {
      setGroupBy(allowedGroupByOptions[0]);
    }
  }, [allowedGroupByOptions, groupBy]);

  // Fetch loyalty programs data
  useEffect(() => {
    const fetchLoyaltyPrograms = async () => {
      setIsLoading(true);
      try {
        const params = {
          group_by: groupBy,
        };
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;

        const data = await getLoyaltyProgramsSold(params);
        setLoyaltyData(data);

        const totalOrders = data.reduce((programAcc, program) => {
          return (
            programAcc +
            program.loyalty_levels.reduce((levelAcc, level) => {
              return (
                levelAcc +
                (level.data
                  ? level.data.reduce(
                      (dataAcc, item) => dataAcc + item.orders,
                      0,
                    )
                  : 0)
              );
            }, 0)
          );
        }, 0);

        setTotalLoyaltyPrograms(totalOrders);
      } catch (error) {
        console.error('Error fetching loyalty programs sold:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoyaltyPrograms();
  }, [groupBy, startDate, endDate]);

  const LevelSelector = ({ programId, levels, selectedLevel, onChange }) => (
    <FormControl mb="10px" w="auto" display="inline-block" fontSize="sm">
      <Select
        fontSize="sm"
        variant="subtle"
        value={selectedLevel}
        onChange={(e) => onChange(programId, e.target.value)}
      >
        <option value="all">All Levels</option>
        {levels.map((level) => (
          <option key={level.level_id} value={level.level_id}>
            {level.level_name}
          </option>
        ))}
      </Select>
    </FormControl>
  );

  const renderCharts = () => {
    return loyaltyData
      .filter((program) =>
        program.loyalty_levels?.some(
          (level) => level.data && level.data.length > 0,
        ),
      )
      .map((program) => {
        const programId = program.loyalty_program_id;
        const programName =
          program.loyalty_program_name || `Program ${programId}`;
        const selectedLevel = levelsSelections[programId] || 'all';

        const allPeriods = new Set();
        program.loyalty_levels.forEach((level) => {
          level.data.forEach((item) => allPeriods.add(item.period));
        });
        const sortedPeriods = Array.from(allPeriods).sort();

        let processedData = [];
        if (selectedLevel === 'all') {
          // Sum up data for all levels per period
          processedData = sortedPeriods.map((period) => {
            const periodData = { period, orders: 0, sales: 0 };
            program.loyalty_levels.forEach((level) => {
              if (level.data && level.data.length > 0) {
                const dataItem = level.data.find(
                  (item) => item.period === period,
                );
                if (dataItem) {
                  periodData.orders += dataItem.orders;
                  periodData.sales += dataItem.sales;
                }
              }
            });
            return periodData;
          });
        } else {
          // Get data for selected level only
          const selectedLevelData = program.loyalty_levels.find(
            (level) => level.level_id === parseInt(selectedLevel),
          );
          processedData = selectedLevelData
            ? sortedPeriods.map((period) => {
                const dataItem = selectedLevelData.data.find(
                  (item) => item.period === period,
                );
                return {
                  period,
                  orders: dataItem ? dataItem.orders : 0,
                  sales: dataItem ? dataItem.sales : 0,
                };
              })
            : [];
        }

        // Prepare chart categories
        const chartCategories = processedData.map((item) => {
          let date = parseISO(item.period);

          if (groupBy === 'week') {
            // Parse week number and year
            const year = parseInt(item.period.slice(0, 4), 10);
            const week = parseInt(item.period.slice(4), 10);
            date = startOfWeek(addWeeks(new Date(year, 0, 1), week - 1), {
              weekStartsOn: 1,
            });
          }

          if (!isValid(date)) {
            console.error(`Invalid date value: ${item.period}`);
            return item.period; // Return the original value if the date is invalid
          }

          switch (groupBy) {
            case 'day':
              return format(date, 'dd MMM');
            case 'week':
              return `W${format(date, 'w yyyy')}`;
            case 'month':
              return format(date, 'MMM');
            case 'year':
              return format(date, 'yyyy');
            default:
              return item.period;
          }
        });

        const chartData = [
          {
            name: 'Orders',
            data: processedData.map((item, index) => ({
              x: chartCategories[index],
              y: item.orders,
            })),
          },
        ];

        // Chart options
        const chartOptions = {
          chart: {
            toolbar: { show: false },
          },
          xaxis: {
            show: false,
            categories: chartCategories,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          grid: {
            show: false,
          },
          yaxis: {
            show: false,
          },
          stroke: {
            curve: 'smooth',
            width: 5,
          },
          colors: ['#3182CE'],
          tooltip: {
            shared: true,
            intersect: false,
          },
        };

        return (
          <Box key={`${programId}-${selectedLevel}`} w="100%" mb="40px">
            <Flex justify="space-between" mb="5px" pl="20px" pr="20px">
              <Text fontSize="xl" fontWeight="bold">
                {programName}
              </Text>
              {/* Level Selector */}
              <LevelSelector
                programId={programId}
                levels={program.loyalty_levels}
                selectedLevel={selectedLevel}
                onChange={(id, value) =>
                  setLevelSelections((prev) => ({
                    ...prev,
                    [id]: value,
                  }))
                }
              />
            </Flex>
            <LineChart
              key={`${programId}-${selectedLevel}`}
              chartData={chartData}
              chartOptions={chartOptions}
              pl="10px"
            />
          </Box>
        );
      });
  };

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Flex
            justify="space-between"
            px="20px"
            pt="5px"
            w="100%"
            flexWrap="wrap"
          >
            {/* Left side - Total Loyalty Programs */}
            <Flex align="center" mb="10px">
              <Flex flexDirection="column" me="20px">
                <Text
                  color={textColor}
                  fontSize="34px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {totalLoyaltyPrograms}
                </Text>
                <Text
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                  mt="4px"
                >
                  Total Loyalty Programs Sold
                </Text>
              </Flex>
            </Flex>

            {/* Right side - Filter controls */}
            <Flex
              mb="10px"
              ml="auto"
              alignItems="center"
              flexWrap="wrap"
              gap={2}
            >
              {/* Time Period */}
              <FormControl w="auto">
                <Select
                  fontSize="sm"
                  variant="subtle"
                  value={groupBy}
                  onChange={(e) => setGroupBy(e.target.value)}
                >
                  {allowedGroupByOptions.map((option) => (
                    <option key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
          </Flex>

          <Box minH="200px" mt="auto" w="100%">
            {renderCharts()}
          </Box>
        </>
      )}
    </Card>
  );
}
