import {
  Box,
  Icon,
  Text,
  useColorModeValue,
  Flex,
  Input,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import React from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';

export default function DropzoneLoyalty(props) {
  const { onDrop, content, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 });
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brand = useColorModeValue('brand.500', 'brand.400');

  return (
    <Box py="4px" {...rest}>
      <Flex
        align="center"
        justify="center"
        bg={bg}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        maxW="100%"
        h={{ base: '160px', '3xl': '300px' }}
        cursor="pointer"
        {...getRootProps({ className: 'dropzone' })}
      >
        <Input variant="main" name="file" {...getInputProps()} />
        <Box maxW="100%" textAlign="center">
          <Icon as={MdOutlineCloudUpload} w="60px" h="60px" color={textColor} />
          <Text
            mb="8px"
            fontSize="md"
            w="100%"
            maxW="100%"
            fontWeight="700"
            color={textColor}
            whiteSpace="pre-wrap"
          >
            Drop your image here, or{' '}
            <Text as="span" fontSize="lg" fontWeight="700" color={brand}>
              click to browse
            </Text>
          </Text>
          <Text
            fontSize="sm"
            fontWeight="500"
            color="secondaryGray.500"
            whiteSpace="pre-wrap !important"
          >
            PNG, JPG, GIF, and WEBP files are allowed
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
