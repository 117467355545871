import apiClient from './axios';

export const fetchAutomations = async () => {
  const response = await apiClient.get('/loyalty-automations/');
  return response.data;
};

export const fetchAutomationRules = async (uuid) => {
  const response = await apiClient.get(`/loyalty-automation-rules?automation_uuid=${uuid}`);
  return response.data;
};

export const createLoyaltyAutomation = async (data) => {
  try {
    const response = await apiClient.post('/loyalty-automations', data);
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty automation:', error);
    throw error;
  }
};

export const createLoyaltyAutomationRule = async (data) => {
  try {
    const response = await apiClient.post('/loyalty-automation-rules', data);
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty automation rule:', error);
    throw error;
  }
};

export const deleteLoyaltyAutomation = async (uuid) => {
  try {
    await apiClient.delete(`/loyalty-automations/${uuid}`);
  } catch (error) {
    console.error('Error deleting loyalty automation:', error);
    throw error;
  }
};

export const deleteLoyaltyAutomationRule = async (uuid) => {
  try {
    await apiClient.delete(`/loyalty-automation-rules/${uuid}`);
  } catch (error) {
    console.error('Error deleting loyalty automation rule:', error);
    throw error;
  }
};

export const updateLoyaltyAutomation = async (data) => {
  try {
    await apiClient.put(`/loyalty-automations/${data.uuid}`, data);
  } catch (error) {
    console.error('Error updating loyalty automation:', error);
    throw error;
  }
};

export const updateLoyaltyAutomationRule = async (data) => {
  try {
    await apiClient.put(`/loyalty-automation-rules/${data.uuid}`, data);
  } catch (error) {
    console.error('Error updating loyalty automation rule:', error);
    throw error;
  }
};

export const fetchLoyaltyPrograms = async () => {
  try {
    const response = await apiClient.get('/loyalty-programs');
    return response.data;
  } catch (error) {
    console.error('Error fetching loyalty programs:', error);
    throw error;
  }
};

export const fetchVouchers = async () => {
  try {
    const response = await apiClient.get('/products?product_type=all');
    return response.data;
  } catch (error) {
    console.error('Error fetching vouchers:', error);
    throw error;
  }
};