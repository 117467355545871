// Details.js

import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import InputField from 'components/fields/InputField';
import React from 'react';

export default function Details({ productPrice, setProductPrice }) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  return (
    <FormControl>
      <Card mb="20px">
        <Flex direction="column" mb="40px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Pricing Details
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Here you can change your product pricing details
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Flex direction="column">
            <SimpleGrid
              mb="20px"
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: '20px', xl: '20px' }}
            >
              <InputField
                mb="0px"
                me="30px"
                id="price"
                label="Price"
                placeholder="e.g., $99"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
              />
            </SimpleGrid>
          </Flex>
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
