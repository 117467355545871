import {
  Box,
  Text,
  Flex,
  Input,
  IconButton,
  Image,
  Button,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Checkbox,
  Select,
  HStack,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Textarea,
} from '@chakra-ui/react';
import React from 'react';
import DropzoneLoyalty from './LoyaltyDropzone';
import LoyaltyLevelModalAlert from './LoyaltyLevelModalAlert';
import {
  handleImageUpload,
  handleSetFeaturedImage,
  handleDeleteImage,
} from '../../api/product';
import { StarIcon, DeleteIcon } from '@chakra-ui/icons';

export default function LoyaltyLevelModal({
  isModalOpen,
  setIsModalOpen,
  levelModalData,
  setLevelModalData,
  handleModalInputChange,
  handleModalSave,
  isArchiveDialogOpen,
  setIsArchiveDialogOpen,
  handleArchive,
  toast,
}) {
  const [isUploading, setIsUploading] = React.useState(false);
  const cancelRef = React.useRef();

  const handleImageUploadWrapper = (files) => {
    setIsUploading(true); // Start spinner

    handleImageUpload(files, levelModalData.product_uuid)
      .then((response) => {
        setTimeout(() => {
          setLevelModalData((prevData) => ({
            ...prevData,
            images: [...prevData.images, response],
          }));
          setIsUploading(false); // Stop spinner
        }, 2000); // Delay of 2 seconds
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: `Error uploading image: ${error}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleSetFeaturedImageWrapper = (uuid) => {
    handleSetFeaturedImage(levelModalData.product_uuid, uuid)
      .then(() => {
        setLevelModalData((prevData) => ({
          ...prevData,
          images: prevData.images.map((image) =>
            image.uuid === uuid
              ? { ...image, is_featured: true }
              : { ...image, is_featured: false },
          ),
        }));
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: `Error setting featured image: ${error}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleDeleteImageWrapper = (uuid) => {
    setIsUploading(true); // Start spinner

    handleDeleteImage(levelModalData.product_uuid, uuid)
      .then(() => {
        setTimeout(() => {
          setLevelModalData((prevData) => ({
            ...prevData,
            images: prevData.images.filter((image) => image.uuid !== uuid),
          }));
          setIsUploading(false); // Stop spinner
        }, 1000); // Delay of 2 seconds
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: `Error deleting image: ${error}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {levelModalData.name ? 'Edit Loyalty Level' : 'Add Loyalty Level'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb="15px">
            <FormLabel htmlFor="is_active">Active Status</FormLabel>
            <Flex align="center">
              <Switch
                id="is_active"
                isChecked={levelModalData.is_active}
                onChange={(e) =>
                  setLevelModalData({
                    ...levelModalData,
                    is_active: e.target.checked,
                  })
                }
                colorScheme="blue"
                size="lg"
              />
              <Text ml="10px">
                {levelModalData.is_active ? 'Active' : 'Inactive'}
              </Text>
            </Flex>
          </FormControl>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            mb="15px"
            id="name"
            placeholder="Name"
            value={levelModalData.name}
            onChange={handleModalInputChange}
            required="true"
          />
          <FormLabel htmlFor="description">Description</FormLabel>
          <Textarea
            mb="15px"
            id="description"
            placeholder="Description"
            value={levelModalData.description}
            onChange={handleModalInputChange}
            required="true"
          />
          <FormLabel htmlFor="price">Price</FormLabel>
          <NumberInput
            mb="15px"
            id="price"
            value={levelModalData.price ?? 0}
            onChange={(value) => handleModalInputChange(value, 'price')}
            allowMouseWheel
            precision={2}
            step={0.1}
            required="true"
          >
            <NumberInputField placeholder="Price" />
          </NumberInput>

          <FormLabel htmlFor="quantity">Quantity</FormLabel>
          <Flex mb="15px">
            <NumberInput
              disabled={levelModalData.is_infinite}
              id="quantity"
              placeholder="Quantity"
              value={levelModalData.quantity ?? 0}
              onChange={(value) => handleModalInputChange(value, 'quantity')}
              allowMouseWheel
              mr="10px"
              w="100%"
              precision={0}
              step={1}
              min={levelModalData.sold_quantity || 0}
            >
              <NumberInputField placeholder="Quantity" />
            </NumberInput>
            <Checkbox
              isChecked={levelModalData.is_infinite}
              onChange={(e) =>
                setLevelModalData({
                  ...levelModalData,
                  is_infinite: e.target.checked,
                })
              }
            >
              Infinite?
            </Checkbox>
          </Flex>
          {levelModalData.sold_quantity > 0 && (
            <Text mb="10px" color="gray.500" fontSize="sm">
              Sold Quantity: {levelModalData.sold_quantity}
            </Text>
          )}

          <FormControl mb="15px">
            <FormLabel htmlFor="expirationTimePeriod">
              Expiration Time
            </FormLabel>
            <HStack>
              <Input
                id="expirationTimePeriod"
                placeholder="Period"
                value={levelModalData.expirationTimePeriod}
                onChange={handleModalInputChange}
                type="number"
                w="80%"
                disabled={!levelModalData.has_expiration}
              />
              <Select
                id="expirationTimeUnit"
                placeholder="Select Time Unit"
                value={levelModalData.expirationTimeUnit}
                onChange={handleModalInputChange}
                disabled={!levelModalData.has_expiration}
              >
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </Select>
              <Checkbox
                isChecked={levelModalData.has_expiration}
                onChange={(e) =>
                  setLevelModalData({
                    ...levelModalData,
                    has_expiration: e.target.checked,
                  })
                }
              >
                Expires?
              </Checkbox>
            </HStack>
          </FormControl>
          <FormControl mb="15px">
            {/* Other form controls */}
            <FormLabel htmlFor="image" pt="10px">
              Image
            </FormLabel>
            <DropzoneLoyalty onDrop={handleImageUploadWrapper} />
            <Box mt="10px">
              {levelModalData.images?.map((image) => (
                <Box
                  key={image.uuid}
                  position="relative"
                  display="inline-block"
                  borderRadius="10px"
                  overflow="hidden"
                  width="100px"
                  height="100px"
                  pt="10px"
                  mr="10px"
                >
                  <Image
                    src={image.image_url}
                    alt="Loyalty Level Image"
                    boxSize="100px"
                    objectFit="contain"
                    style={{
                      borderRadius: '10px',
                      clipPath: 'inset(0 round 10px)',
                    }}
                  />
                  <IconButton
                    icon={<StarIcon />}
                    colorScheme={image.is_featured ? 'yellow' : 'gray'}
                    onClick={() => handleSetFeaturedImageWrapper(image.uuid)}
                    position="absolute"
                    top="5px"
                    left="5px"
                    size="sm"
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={() => handleDeleteImageWrapper(image.uuid)}
                    position="absolute"
                    top="5px"
                    right="5px"
                    size="sm"
                  />
                </Box>
              ))}
              {isUploading && (
                <Flex
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="rgba(255, 255, 255, 0.7)"
                  zIndex="1"
                >
                  <Spinner size="xl" />
                </Flex>
              )}
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            colorScheme={levelModalData.is_archived ? 'blue' : 'red'}
            onClick={() => setIsArchiveDialogOpen(true)}
          >
            {levelModalData.is_archived ? 'Unarchive' : 'Archive'}
          </Button>
          <div>
            <Button
              variant="ghost"
              onClick={() => {
                setIsModalOpen(false);
                setLevelModalData({
                  product_uuid: '',
                  loyalty_uuid: '',
                  name: '',
                  description: '',
                  price: 0,
                  expirationTimePeriod: 0,
                  expirationTimeUnit: 'days',
                  is_archived: false,
                  is_active: true,
                  quantity: 0,
                  is_infinite: false,
                  has_expiration: false,
                  images: [],
                });
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleModalSave}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
      <LoyaltyLevelModalAlert
        isArchiveDialogOpen={isArchiveDialogOpen}
        setIsArchiveDialogOpen={setIsArchiveDialogOpen}
        levelModalData={levelModalData}
        handleArchive={handleArchive}
        cancelRef={cancelRef}
      />
    </Modal>
  );
}
