import React from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
  Link,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import Footer from "components/footer/FooterAuthDefault";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { NavLink } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
// Import the background image
import authBackground from "assets/img/auth/authbackground.png";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;

  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w="100%"
        maxW={{ md: "66%", lg: "1313px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent="start"
        direction="column"
      >
        {children}
        <Box
          display={{ base: "none", md: "flex" }}
          h="100%"
          minH="100vh"
          w={{ lg: "50vw", "2xl": "44vw" }}
          position="absolute"
          right="0px"
          bgImage={`url(${authBackground})`}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="center"
          borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            direction="column"
            align="center"
            width="80vh"
            height="80vh"
            bgSize="contain"
            bgRepeat="no-repeat"
            position="relative"
            bgPosition={{ lg: "center" }}
            top="-100px"
          />
          <Box
            textAlign="center"
            mt="20px"
            px="20px"
            position="relative"
            top="-250px"
          >
            <Text color="white" fontSize="lg">
              Learn more about Perxify on
            </Text>
            <Link
              href="https://perxify.com"
              color="white"
              fontWeight="bold"
              fontSize="lg"
              isExternal
            >
              perxify.com
            </Link>
          </Box>
        </Box>
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}

// PROPS
AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
