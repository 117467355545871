import apiClient from './axios';

/**
 * Fetches product discounts based on provided filters.
 *
 * @param {Object} filters - The filters to apply (e.g., product_uuid, loyalty_program_uuid, etc.).
 * @returns {Promise<Array>} - A promise that resolves to an array of discounts.
 */
export const getProductDiscounts = async (filters = {}) => {
  try {
    const response = await apiClient.get('/product-discounts', {
      params: filters,
    });

    if (Array.isArray(response.data)) {
      // When response.data is an array (no discounts), wrap it in an object
      return {
        data: response.data,
        total: response.data.length,
        page: 1,
        limit: response.data.length,
      };
    } else {
      // When response.data is an object with data, total, etc.
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching product discounts:', error);
    throw error;
  }
};

/**
 * Fetches a single product discount by its UUID.
 *
 * @param {string} uuid - The UUID of the discount to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the discount object.
 */
export const getDiscount = async (uuid) => {
  try {
    const response = await apiClient.get(`/product-discounts/${uuid}`);
    return response.data; // Assumes response.data is the discount object
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        throw new Error('Discount not found.');
      }
      // Handle other specific error statuses if necessary
    }
    console.error('Error fetching discount:', error);
    throw error;
  }
};

/**
 * Creates a product discount based on the provided discount type and scope.
 *
 * @param {Object} data - The discount data.
 * @param {string} data.product_uuid - The UUID of the product.
 * @param {number} [data.discount_price] - The fixed discount price.
 * @param {number} [data.discount_percentage] - The percentage discount.
 * @param {string} [data.loyalty_program_uuid] - The UUID of the loyalty program.
 * @param {string} [data.loyalty_level_uuid] - The UUID of the loyalty level.
 * @param {string} data.start_time - The start time of the discount in ISO format.
 * @param {string} data.end_time - The end time of the discount in ISO format.
 * @param {boolean} data.is_active - The status of the discount (active/inactive).
 * @param {string} data.discount_type - The scope of the discount (e.g., 'selected_products').
 * @returns {Promise<Object>} - A promise that resolves to the created discount object.
 */
export const createProductDiscount = async (data) => {
  try {
    // Clean the payload to exclude null or undefined fields
    const payload = cleanPayload({
      product_uuid: data.product_uuid,
      product_uuids: data.product_uuids,
      start_time: data.start_time,
      end_time: data.end_time,
      is_active: data.is_active,
      discount_price: data.discount_price,
      discount_percentage: data.discount_percentage,
      loyalty_program_uuid: data.loyalty_program_uuid,
      loyalty_level_uuid: data.loyalty_level_uuid,
      discount_type: data.discount_type,
    });

    const response = await apiClient.post('/product-discounts', payload);
    return response.data;
  } catch (error) {
    console.error('Error creating product discount:', error);
    throw error;
  }
};

/**
 * Helper function to remove null or undefined fields from an object.
 *
 * @param {Object} obj - The object to clean.
 * @returns {Object} - The cleaned object.
 */
const cleanPayload = (obj) => {
  const allowedNullFields = ['start_time', 'end_time'];
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (allowedNullFields.includes(key)) {
        return true; // Include the field even if null
      }
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== undefined;
    }),
  );
};

/**
 * Updates an existing product discount.
 *
 * @param {string} discountUuid - The UUID of the discount to update.
 * @param {Object} data - The updated discount data.
 * @returns {Promise<Object>} - A promise that resolves to the updated discount object.
 */
export const updateProductDiscount = async (discountUuid, data) => {
  try {
    // Clean the payload to exclude null or undefined fields
    const cleanedData = cleanPayload({
      product_uuid: data.product_uuid,
      product_uuids:
        data.product_uuids && data.product_uuids.length > 0
          ? data.product_uuids.filter((uuid) => uuid && uuid.trim() !== '')
          : undefined, // Exclude if empty
      start_time: data.start_time,
      end_time: data.end_time,
      is_active: data.is_active,
      discount_price: data.discount_price,
      discount_percentage: data.discount_percentage,
      loyalty_program_uuid: data.loyalty_program_uuid,
      loyalty_level_uuid: data.loyalty_level_uuid,
      discount_type: data.discount_type,
    });

    const response = await apiClient.put(
      `/product-discounts/${discountUuid}`,
      cleanedData,
    );
    return response.data; // Assumes response.data is the updated discount
  } catch (error) {
    console.error('Error updating product discount:', error);
    throw error;
  }
};

export const deleteProductDiscount = async (discountUuid) => {
  try {
    await apiClient.delete(`/product-discounts/${discountUuid}`);
  } catch (error) {
    console.error('Error deleting product discount:', error);
    throw error;
  }
};
