import React, {createContext, useState, useContext} from 'react';

const AutomationContext = createContext();

export const useAutomation = () => useContext(AutomationContext);

export const AutomationProvider = ({children}) => {
    const [flowName, setFlowName] = useState('');
    const [flowDescription, setFlowDescription] = useState('');
    const [flowItems, setFlowItems] = useState([]);
    const [automationUuid, setAutomationUuid] = useState('');

    return (
        <AutomationContext.Provider
            value={{
                flowName,
                setFlowName,
                flowItems,
                setFlowItems,
                flowDescription,
                setFlowDescription,
                automationUuid,
                setAutomationUuid,
            }}
        >
            {children}
        </AutomationContext.Provider>
    );
};
