// src/components/discounts/discountColumns.js

import { createColumnHelper } from '@tanstack/react-table';
import { Text, Badge, IconButton, Flex } from '@chakra-ui/react';
import { MdEdit, MdContentCopy } from 'react-icons/md';
import useBrandColor from '../../hooks/useBrandColor'; // Ensure this hook exists

const columnHelper = createColumnHelper();

const useDiscountColumns = (textColor, navigate, handleDuplicate) => {
  const brandColor = useBrandColor();

  return [
    // Expand/Collapse Column
    columnHelper.display({
      id: 'expander',
      header: () => null,
      cell: ({ row }) => {
        const canExpand = row.getCanExpand();
        return canExpand ? (
          <Text
            as="span"
            cursor="pointer"
            onClick={row.getToggleExpandedHandler()}
            mr={2}
          >
            {row.getIsExpanded() ? '▼' : '▶'}
          </Text>
        ) : null;
      },
      enableSorting: false,
      enableHiding: false,
    }),
    // Product Name Column
    columnHelper.accessor('product_name', {
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Product Name
        </Text>
      ),
      cell: (info) => {
        const productName = info.getValue() || 'Multiple Products';
        return (
          <Text
            fontWeight={
              info.row.original.parent_id === null ||
              info.row.original.parent_id === 0
                ? 'bold'
                : 'normal'
            }
          >
            {productName}
          </Text>
        );
      },
    }),
    // Discount Percentage Column
    columnHelper.accessor('discount_percentage', {
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Discount %
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue() !== null ? `${info.getValue()}%` : '-'}
        </Text>
      ),
    }),
    // Discount Price Column
    columnHelper.accessor('discount_price', {
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Discount €
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue() !== null ? `€${info.getValue()}` : '-'}
        </Text>
      ),
    }),
    // To Whom Column
    columnHelper.accessor('discount_type', {
      id: 'to_whom',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          To Whom
        </Text>
      ),
      cell: (info) => {
        const { discount_type, loyalty_program_uuid, loyalty_level_uuid } =
          info.row.original;

        switch (discount_type) {
          case 'all_loyalty_products':
            return <Text>All Loyalty Members</Text>;
          case 'all_general_products':
            return <Text>Everyone</Text>;
          case 'selected_products':
            return <Text>Selected Products</Text>;
          case 'loyalty_products':
            if (loyalty_program_uuid) {
              if (loyalty_level_uuid) {
                return <Text>Loyalty Members with Specific Level</Text>;
              } else {
                return <Text>All Loyalty Members</Text>;
              }
            } else {
              return <Text>Loyalty Members</Text>;
            }
          case 'general_products':
            return <Text>General Products</Text>;
          case 'loyalty_level_products':
            return <Text>Loyalty Level Products</Text>;
          default:
            return <Text>Unknown</Text>;
        }
      },
    }),
    // Period Column
    columnHelper.accessor('start_time', {
      id: 'period',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Period
        </Text>
      ),
      cell: (info) => {
        const startTime = info.getValue();
        const endTime = info.row.original.end_time;

        const start = startTime
          ? new Date(startTime).toLocaleDateString()
          : null;
        const end = endTime ? new Date(endTime).toLocaleDateString() : null;
        const today = new Date().toLocaleDateString();

        if (!start && !end) {
          return <Text>Always</Text>;
        } else if (start && !end) {
          return <Text>{start} - Always</Text>;
        } else if (!start && end) {
          return (
            <Text>
              {today} - {end}
            </Text>
          );
        } else {
          return (
            <Text>
              {start} - {end}
            </Text>
          );
        }
      },
    }),
    // Which Products Column
    columnHelper.accessor('discount_type', {
      id: 'which_products',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Which Products
        </Text>
      ),
      cell: (info) => {
        const discountType = info.getValue();
        switch (discountType) {
          case 'all_loyalty_products':
            return <Text>All Loyalty Products</Text>;
          case 'all_general_products':
            return <Text>All General Products</Text>;
          case 'selected_products':
            return <Text>Specific Products</Text>;
          case 'loyalty_products':
            return <Text>Loyalty Products</Text>;
          case 'general_products':
            return <Text>General Products</Text>;
          case 'loyalty_level_products':
            return <Text>Loyalty Level Products</Text>;
          default:
            return <Text>Unknown</Text>;
        }
      },
    }),
    // Active Status Column
    columnHelper.accessor('is_active', {
      id: 'active_status',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Active
        </Text>
      ),
      cell: (info) => (
        <Badge colorScheme={info.getValue() ? 'green' : 'red'}>
          {info.getValue() ? 'Active' : 'Inactive'}
        </Badge>
      ),
    }),
    // Actions Column
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '14px' }} color="gray.400">
          Actions
        </Text>
      ),
      cell: (info) => (
        <Flex>
          <IconButton
            aria-label="Edit Discount"
            icon={<MdEdit />}
            size="sm"
            colorScheme="blue"
            variant="outline"
            mr={2}
            onClick={() =>
              navigate(`/admin/discounts/edit/${info.row.original.uuid}`)
            }
          />
          <IconButton
            aria-label="Duplicate Discount"
            icon={<MdContentCopy />}
            size="sm"
            colorScheme="teal"
            variant="outline"
            onClick={() => handleDuplicate(info.row.original.uuid)}
          />
        </Flex>
      ),
    }),
  ];
};

export default useDiscountColumns;
