import apiClient from './axios';

export const getLoyaltyPrograms = async () => {
  const response = await apiClient.get('/loyalty-programs/');
  return response.data;
};

export const getLoyaltyLevels = async () => {
  const response = await apiClient.get('/loyalty-levels/');
  return response.data;
};

export const updateLoyaltyProgram = async (uuid, data) => {
  try {
    const response = await apiClient.put(`/loyalty-programs/${uuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty program:', error);
    throw error;
  }
};

export const updateLoyaltyLevel = async (uuid, data) => {
  try {
    const response = await apiClient.put(`/loyalty-levels/${uuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty level:', error);
    throw error;
  }
};

export const updateProduct = async (uuid, data) => {
  try {
    const response = await apiClient.put(`/products/${uuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const createLoyaltyProgram = async (data) => {
  try {
    const response = await apiClient.post('/loyalty-programs', data);
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty program:', error);
    throw error;
  }
};

export const createLoyaltyLevel = async (data) => {
  try {
    const response = await apiClient.post('/loyalty-levels', data);
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty level:', error);
    throw error;
  }
};

export const createProduct = async (data) => {
  try {
    const response = await apiClient.post('/products', data);
    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};