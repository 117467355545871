// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
  Switch,
  VStack,
  Box,
  Spacer,
  useToast,
  Image,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card.js';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getLoyaltyPrograms,
  updateLoyaltyProgram,
  updateLoyaltyLevel,
  updateProduct,
  createLoyaltyLevel,
  createProduct,
} from 'api/loyalty';

import LoyaltyLevelModal from 'components/loyalty/LoyaltyLevelModal';
import { convertToDays, convertFromDays } from 'utils/loyaltyUtils';

export default function LoyaltyDetail() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const navigate = useNavigate();
  const toast = useToast();
  const { uuid } = useParams();
  const [images, setImages] = useState([]);
  const [isProductActive, setIsProductActive] = useState(true);
  const [useCustomContracts, setUseCustomContracts] = useState(true);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [programData, setProgramData] = useState({
    name: '',
    description: '',
    web3_loyalty_contract: '',
    web3_voucher_contract: '',
    products: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [levelModalData, setLevelModalData] = useState({
    product_uuid: '',
    loyalty_uuid: '',
    name: '',
    description: '',
    price: 0,
    expirationTimePeriod: 0,
    expirationTimeUnit: 'days',
    is_archived: false,
    is_active: true,
    quantity: 0,
    sold_quantity: 0,
    is_infinite: false,
    has_expiration: false,
    images: [],
  });

  // Fetch program data if editing an existing program
  useEffect(() => {
    if (uuid) {
      getLoyaltyPrograms()
        .then((response) => {
          const filteredRespone = response.filter(
            (program) => program.uuid === uuid,
          );
          setProgramData(filteredRespone[0]);
          setIsProductActive(filteredRespone[0].is_active);
          setUseCustomContracts(false);
        })

        .catch((error) => {
          console.error('Error fetching program data', error);
        });
    }
  }, [uuid]);

  const handleSave = () => {
    const { products, ...updatedProgramData } = {
      ...programData,
      is_active: isProductActive,
    };
    updateLoyaltyProgram(uuid, updatedProgramData)
      .then(() => {
        toast({
          title: 'Success',
          description: 'Loyalty program updated successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/admin/loyalty-programs');
      })
      .catch((error) => {
        console.error('Error updating loyalty program:', error);
      });
  };

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    setProgramData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }, []);

  const handleModalInputChange = (e, id) => {
    const value = e.target ? e.target.value : e;
    const inputId = id || e.target.id;
    if (value === '') {
      setLevelModalData((prevState) => ({
        ...prevState,
        [inputId]: '',
      }));
    } else {
      setLevelModalData((prevState) => ({
        ...prevState,
        [inputId]:
          inputId === 'price' || inputId === 'quantity' ? value : value,
      }));
    }
  };

  const openModal = (productData) => {
    if (productData) {
      setImages(productData.images ? productData.images : []);
      setLevelModalData({
        product_uuid: productData.product_uuid,
        loyalty_uuid: productData.loyalty_level_uuid,
        name: productData.name,
        description: productData.description,
        price: productData.price,
        expirationTimePeriod: productData.expirationTimePeriod,
        expirationTimeUnit: 'days',
        is_archived: productData.is_archived,
        is_active: productData.is_active,
        quantity: productData.quantity,
        sold_quantity: productData.sold_quantity,
        is_infinite: !productData.quantity ? true : false,
        has_expiration: !productData.expirationTimePeriod ? false : true,
        images: productData.images,
      });
    } else {
      setLevelModalData({
        product_uuid: '',
        loyalty_uuid: '',
        name: '',
        description: '',
        price: 0,
        expirationTimePeriod: 0,
        expirationTimeUnit: 'days',
        is_archived: false,
        is_active: true,
        quantity: 0,
        sold_quantity: 0,
        is_infinite: false,
        has_expiration: false,
        images: [],
      });
    }
    setIsModalOpen(true);
  };

  const handleModalSave = () => {
    const {
      name,
      description,
      price,
      quantity,
      is_infinite,
      has_expiration,
      expirationTimePeriod,
      expirationTimeUnit,
    } = levelModalData;

    // Validation checks
    if (!name || !description || price === undefined || price === null) {
      toast({
        title: 'Error',
        description: 'Name, description, and price are required.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (!is_infinite && (quantity === undefined || quantity === null)) {
      toast({
        title: 'Error',
        description: 'Quantity is required when not infinite.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (has_expiration && (!expirationTimePeriod || !expirationTimeUnit)) {
      toast({
        title: 'Error',
        description: 'Specify expiration time period and unit.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const days = convertToDays(
      levelModalData.expirationTimePeriod,
      levelModalData.expirationTimeUnit,
    );

    const isEditing = !!levelModalData.loyalty_uuid;

    const levelRequest = isEditing
      ? updateLoyaltyLevel(levelModalData.loyalty_uuid, {
        name: levelModalData.name,
        expiration_period: days,
        is_active: levelModalData.is_active,
        is_archived: levelModalData.is_archived,
      })
      : createLoyaltyLevel({
        name: levelModalData.name,
        expiration_period: days,
        is_active: levelModalData.is_active,
        is_archived: levelModalData.is_archived,
        loyalty_program_uuid: uuid,
      });
    levelRequest
      .then((response) => {
        const levelUuid = response.uuid;

        const productRequest = isEditing
          ? updateProduct(levelModalData.product_uuid, {
            name: levelModalData.name,
            description: levelModalData.description,
            price: levelModalData.price,
            quantity: levelModalData.quantity,
            loyalty_program_uuid: uuid,
            loyalty_level_uuid: levelUuid,
            is_active: levelModalData.is_active,
            is_archived: levelModalData.is_archived,
          })
          : createProduct({
            name: levelModalData.name,
            description: levelModalData.description,
            price: levelModalData.price ? levelModalData.price : 0,
            quantity: levelModalData.quantity ? levelModalData.quantity : 0,
            loyalty_program_uuid: uuid,
            is_active: levelModalData.is_active,
            is_archived: levelModalData.is_archived,
            loyalty_level_uuid: levelUuid,
          });

        productRequest
          .then((response) => {
            const newProduct = {
              ...response,
              product_uuid: response.uuid,
            };

            // Update the program data with the new product
            setProgramData((prevState) => ({
              ...prevState,
              products: isEditing
                ? prevState.products.map((product) =>
                  product.product_uuid === newProduct.product_uuid
                    ? {
                      ...product,
                      ...newProduct,
                      images: levelModalData.images,
                    }
                    : product,
                )
                : [
                  ...prevState.products,
                  {
                    ...newProduct,
                    images: levelModalData.images,
                  },
                ],
            }));

            setLevelModalData({
              product_uuid: '',
              loyalty_uuid: '',
              name: '',
              description: '',
              price: 0,
              expirationTimePeriod: 0,
              expirationTimeUnit: 'days',
              is_archived: false,
              is_active: true,
              quantity: 0,
              is_infinite: false,
              has_expiration: false,
              images: [],
            });

            setIsModalOpen(false);
          })
          .catch((error) => {
            toast({
              title: 'Error',
              description: `Error saving product: ${error}`,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
          });
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: `Error saving loyalty level: ${error}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleArchive = async () => {
    try {
      const response = await updateLoyaltyLevel(levelModalData.loyalty_uuid, {
        is_archived: !levelModalData.is_archived,
      });

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Loyalty level archived successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setIsModalOpen(false);
        setLevelModalData((prevState) => ({
          ...prevState,
          is_archived: !levelModalData.is_archived,
        }));
      } else {
        toast({
          title: 'Error',
          description: 'Error archiving loyalty level',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error archiving loyalty level:', error);
    } finally {
      setIsArchiveDialogOpen(false);
    }
  };

  return (
    <FormControl pt={{ sm: '125px', md: '75px' }}>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
        {/* Left Column: Program Information */}
        <Card>
          <Flex direction="column" mb="40px" ms="10px">
            <Flex justify="space-between" align="center">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                Program Information
              </Text>
              <Flex align="center">
                <Text
                  fontSize="xl"
                  color={textColorPrimary}
                  mr="10px"
                  fontWeight="bold"
                >
                  Active Status
                </Text>
                <Switch
                  isChecked={isProductActive}
                  onChange={(e) => setIsProductActive(e.target.checked)}
                  colorScheme="blue"
                  size="lg"
                />
              </Flex>
            </Flex>
            <Text fontSize="md" color={textColorSecondary}>
              Edit the details of your loyalty program
            </Text>
          </Flex>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
            {/* Left Column: Name and Description */}
            <Flex direction="column">
              <InputField
                mb="25px"
                id="name"
                label="Program Name"
                placeholder="Enter program name"
                value={programData.name}
                onChange={handleInputChange}
              />
              <TextField
                mb="25px"
                id="description"
                label="Description"
                placeholder="Enter program description"
                h="150px"
                value={programData.description}
                onChange={handleInputChange}
              />
            </Flex>
            {/* Right Column: Contract Addresses and Toggle */}
            <Flex direction="column">
              <Flex justify="space-between" align="center" mb="25px">
                <Box ml="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color={textColorPrimary}
                    mb="8px"
                  >
                    Use Custom Contracts
                  </Text>
                  <Text fontSize="sm" color={textColorSecondary}>
                    Perxify's or custom contracts
                  </Text>
                </Box>
                <Switch
                  isChecked={useCustomContracts}
                  onChange={() => setUseCustomContracts(!useCustomContracts)}
                />
              </Flex>

              <Spacer />

              <InputField
                mb="25px"
                id="web3_loyalty_contract"
                label="Loyalty Contract Address"
                placeholder="Enter loyalty contract address"
                value={programData.web3_loyalty_contract}
                onChange={handleInputChange}
                isDisabled={!useCustomContracts}
              />
              <InputField
                mb="25px"
                id="web3_voucher_contract"
                label="Voucher Contract Address"
                placeholder="Enter voucher contract address"
                value={programData.web3_voucher_contract}
                onChange={handleInputChange}
                isDisabled={!useCustomContracts}
              />
            </Flex>
          </SimpleGrid>
          <Button
            variant="brand"
            minW="183px"
            fontSize="sm"
            fontWeight="500"
            ms="auto"
            mt="20px"
            onClick={handleSave}
          >
            Save changes
          </Button>
        </Card>

        {/* Right Column: Loyalty Levels */}
        <Card>
          <Flex direction="column" mb="40px" ms="10px">
            <Flex justify="space-between" align="center">
              <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                Loyalty Levels
              </Text>
              <Button
                variant="brand"
                colorScheme="brand"
                textColor="brand"
                bgColor="gray.100"
                minW="140px"
                h="35px"
                fontSize="xl"
                lineHeight="35px"
                sx={{
                  '&:hover': {
                    bgColor: 'gray.300',
                  },
                }}
                onClick={() => openModal()}
              >
                +
              </Button>
            </Flex>
            <Text fontSize="md" color={textColorSecondary}>
              Manage the levels of your loyalty program
            </Text>
          </Flex>
          <VStack spacing="20px">
            {programData.products && programData.products.length > 0 ? (
              programData.products.map((product) => {
                const featuredImage = product.images?.find(
                  (image) => image.is_featured,
                );

                return (
                  <Box
                    key={product.product_uuid}
                    w="100%"
                    p="10px"
                    borderRadius="lg"
                    boxShadow="md"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center">
                      {featuredImage && (
                        <Box
                          borderRadius="10px"
                          overflow="hidden"
                          width="100px"
                          height="100px"
                          position="relative"
                          pt="10px"
                          mr="10px"
                        >
                          <Image
                            src={featuredImage.image_url}
                            alt={product.name}
                            objectFit="contain"
                            style={{
                              borderRadius: '10px',
                              clipPath: 'inset(0 round 10px)',
                            }}
                          />
                        </Box>
                      )}
                      <Box>
                        <Flex alignItems="center" mb="5px">
                          <Text fontSize="md" fontWeight="bold">
                            {product.name}
                          </Text>
                          {product.quantity > 0 &&
                            product.sold_quantity >= product.quantity && (
                              <Text
                                fontSize="md"
                                color="red.500"
                                fontWeight="bold"
                                ml="10px"
                              >
                                Sold Out
                              </Text>
                            )}
                        </Flex>
                        <Text fontSize="sm" color={textColorSecondary} mb="5px">
                          Is Active: {product.is_active ? 'Yes' : 'No'}
                        </Text>
                        <Text fontSize="sm" color={textColorSecondary} mb="5px">
                          Default Expiration:{' '}
                          {convertFromDays(product.expiration_period)}
                        </Text>
                        <Text fontSize="sm" color={textColorSecondary} mb="5px">
                          Quantity: {product.quantity || 'Infinite'}
                        </Text>
                        {product.quantity > 0 && (
                          <Text fontSize="sm" color={textColorSecondary}>
                            Sold Quantity: {product.sold_quantity}
                          </Text>
                        )}
                      </Box>
                    </Box>
                    <Box
                      as="button"
                      onClick={() => openModal(product)}
                      aria-label="Edit"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      p="4"
                      mr="30px"
                      borderRadius="md"
                      _hover={{ bg: 'gray.200' }}
                    >
                      <EditIcon w={5} h={5} />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Text fontSize="md" color={textColorSecondary}>
                Levels will appear here
              </Text>
            )}
          </VStack>
        </Card>
      </SimpleGrid>

      {/* Loyalty Level Modal */}
      <LoyaltyLevelModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        levelModalData={levelModalData}
        setLevelModalData={setLevelModalData}
        handleModalInputChange={handleModalInputChange}
        images={images}
        setImages={setImages}
        handleModalSave={handleModalSave}
        isArchiveDialogOpen={isArchiveDialogOpen}
        setIsArchiveDialogOpen={setIsArchiveDialogOpen}
        handleArchive={handleArchive}
        toast={toast}
      />
    </FormControl>
  );
}
