import React, { createContext, useContext, useState, useEffect } from 'react';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const [breadcrumb, setBreadcrumb] = useState(() => {
    const savedBreadcrumb = sessionStorage.getItem('breadcrumb');
    return savedBreadcrumb ? JSON.parse(savedBreadcrumb) : [];
  });

  useEffect(() => {
    sessionStorage.setItem('breadcrumb', JSON.stringify(breadcrumb));
  }, [breadcrumb]);

  const updateBreadcrumb = (newRoute) => {
    setBreadcrumb((prev) => {
      const lastCrumb = prev[prev.length - 1];
      const newRoutePath = newRoute.path || "";
      const newRouteSegments = newRoutePath.split('/').filter(Boolean);
      
      const isMainPage = newRouteSegments.length === 2;
  
      // Check if the new route is a main page
      const hasMainPageInPrev = prev.some((crumb) => {
        const prevSegments = crumb.path.split('/').filter(Boolean);
        return newRouteSegments[1] && prevSegments[1] === newRouteSegments[1];
      });
  
      // Reset breadcrumb if it's a new main page
      if (isMainPage && !hasMainPageInPrev) {
        return [{ name: newRoute.name, path: newRoutePath }];
      }
  
      // Check if the new route is a valid subpage
      const lastCrumbSegments = lastCrumb ? lastCrumb.path.split('/').filter(Boolean) : [];
      const isSubpage = lastCrumb && newRoutePath.startsWith(lastCrumb.path) && newRouteSegments.length > lastCrumbSegments.length;
  
      if (isSubpage) {
        // Find the main page for the current subpage
        const mainPage = prev.find((crumb) => newRoutePath.startsWith(crumb.path) && crumb.path.split('/').length === 3);
        if (mainPage) {
          return [...prev, { name: newRoute.name, path: newRoutePath }];
        }
      }
  
      // Handle main page changes
      const currentMainPage = prev.find((crumb) => {
        const segments = crumb.path.split('/').filter(Boolean);
        return segments.length === 2 || segments.length === 3;
      });
  
      if (currentMainPage && !newRoutePath.startsWith(currentMainPage.path)) {
        return [{ name: newRoute.name, path: newRoutePath }];
      }
  
      // Check if we need to reset to a new main page and add the subpage
      const mainPageInHierarchy = prev.find((crumb) =>
        newRoutePath.startsWith(crumb.path) &&
        crumb.path.split('/').slice(1).length === newRouteSegments.length - 1
      );
  
      if (mainPageInHierarchy) {
        return [mainPageInHierarchy, { name: newRoute.name, path: newRoutePath }];
      }
  
      // Default: Keep the previous breadcrumb
      return prev;
    });
  };


  
  const resetBreadcrumb = () => {
    setBreadcrumb([]);
  };

  return (
    <NavigationContext.Provider
      value={{ breadcrumb, updateBreadcrumb, resetBreadcrumb }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
