// src/api/companies.js
import apiClient from './axios';

export const getCompanies = async () => {
  try {
    const response = await apiClient.get('/companies');
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};

export const getCompanyDetails = async (companyUuid) => {
  const response = await apiClient.get(`/company/${companyUuid}`);
  return response.data;
};

export const getCompany = async () => {
  try {
    const response = await apiClient.get('/company');
    return response.data;
  } catch (error) {
    console.error('Error fetching company:', error);
    throw error;
  }
};

export const createCompany = async (data) => {
  try {
    const response = await apiClient.post('/companies', data);
    return response.data;
  } catch (error) {
    console.error('Error creating company:', error);
    throw error;
  }
};

export const updateCompany = async (companyUuid, data) => {
  try {
    const response = await apiClient.put(`/companies/${companyUuid}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating company:', error);
    throw error;
  }
};

export const deleteCompany = async (companyUuid) => {
  try {
    await apiClient.delete(`/companies/${companyUuid}`);
  } catch (error) {
    console.error('Error deleting company:', error);
    throw error;
  }
};

export const uploadCompanyImage = async (imageType, image) => {
  try {
    const formData = new FormData();
    formData.append('file', image);

    const response = await apiClient.post(
      `/company/images/${imageType}`,
      formData
    );

    return response.data;
  } catch (error) {
    console.error(`Error uploading ${imageType} image:`, error);
    throw error;
  }
};

export const updateCmsBlocks = async (companyUuid, cmsBlocks) => {
  console.log('Updating CMS blocks:', cmsBlocks);
  try {
    const response = await apiClient.put(`/companies/${companyUuid}`, {
      uuid: companyUuid,
      ...cmsBlocks
    });
    return response.data;
  } catch (error) {
    console.error('Error updating CMS blocks:', error);
    throw error;
  }
};
