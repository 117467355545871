// src/contexts/AuthContext.js
import React, {createContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import {login as authServiceLogin} from '../services/authService';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkLoginState = async () => {
            try {
                const token = Cookies.get('token');
                if (token) {
                    setIsAuthenticated(true);
                } else {
                    navigate('/auth/login');
                }
            } catch (error) {
                console.error('Error checking login state', error);
            } finally {
                setLoading(false);
            }
        };
        checkLoginState();
    }, [navigate]);


    const login = async (email, password) => {
        try {
            await authServiceLogin(email, password);
            setIsAuthenticated(true);
            navigate('/');
        } catch (error) {
            console.error('Login error', error);
        }
    };

    const logout = () => {
        Cookies.remove('token', {
            secure: process.env.NODE_ENV !== 'development',
            sameSite: 'Strict',
        });
        Cookies.remove('x-company', {
            secure: process.env.NODE_ENV !== 'development',
            sameSite: 'Strict',
        });
        setIsAuthenticated(false);
        navigate('/auth/sign-in');
    };


    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout, loading}}>
            {children}
        </AuthContext.Provider>
    );
};