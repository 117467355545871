// ProductEdit.js
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Button,
  useToast,
  Text,
  Switch,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  useDisclosure,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import InputField from '../../components/fields/InputField';
import Dropzone from '../../components/products/Dropzone';
import { MdOutlineCloudUpload } from 'react-icons/md';
import Details from '../../components/products/Details';

// Import API functions
import {
  getProductDetails,
  updateProduct,
  handleImageUpload,
  deleteProduct,
} from '../../api/product';

export default function ProductEdit() {
  const { uuid } = useParams(); // Get the product ID from the URL
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); // For controlling the modal
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  // State variables
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [isFeatured, setIsFeatured] = useState(false);
  const [image, setImage] = useState(null);

  // New State Variables for Quantity and Sold Quantity
  const [quantity, setQuantity] = useState(0); // For managing stock levels
  const [soldQuantity, setSoldQuantity] = useState(0); // For displaying sold items

  // Loading state
  const [loading, setLoading] = useState(true);

  // Fetch the product details
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const product = await getProductDetails(uuid);

        setProductName(product.name);
        setProductPrice(product.price);
        setProductDescription(product.description);
        setIsFeatured(product.is_featured);
        setQuantity(product.quantity || 0); // Set quantity
        setSoldQuantity(product.sold_quantity || 0); // Set soldQuantity

        // Log image URLs for debugging
        if (product.images && product.images.length > 0) {
          setImage(product.images[0].image_url);
        } else {
          console.warn('No images found for product.');
          setImage(null);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch product details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false); // Data fetching is complete
      }
    };
    fetchProduct();
  }, [uuid, toast]);
  const handleImageChange = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      // Validate file type
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        console.error('Invalid file type:', file.type);
        toast({
          title: 'Invalid file type.',
          description: 'Only PNG, JPG, and GIF files are allowed.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Validate file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        console.error('File size exceeds limit:', file.size);
        toast({
          title: 'File too large.',
          description: 'Image size should be less than 5MB.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setImage(file);
      console.log('Current Image:', file); // Adjusted log statement
    } else {
      console.warn('No files were accepted by Dropzone.');
    }
  };

  const handleSave = async () => {
    const updatedProduct = {
      name: productName,
      price: Number(productPrice),
      description: productDescription,
      quantity: quantity, // Include quantity
      is_featured: isFeatured,
    };

    try {
      await updateProduct(uuid, updatedProduct);

      if (image && typeof image !== 'string') {
        const uploadResponse = await handleImageUpload([image], uuid);
      } else {
      }

      toast({
        title: 'Product updated.',
        description: 'The product has been updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/admin/products/product-overview');
    } catch (error) {
      console.error('Error updating product:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteProduct(uuid);
      toast({
        title: 'Product deleted.',
        description: 'The product has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/admin/products/product-overview');
    } catch (error) {
      console.error('Error deleting product:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while deleting the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onClose(); // Close the modal after deletion attempt
    }
  };

  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10) || 0;
    if (newQuantity >= soldQuantity) {
      setQuantity(newQuantity);
    } else {
      toast({
        title: 'Invalid Quantity',
        description: 'Quantity cannot be less than the sold quantity.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    // Optionally, you can use a spinner or skeleton components
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid
        columns={{ sm: 1, xl: 2 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Left Column */}
        <Flex direction="column">
          {/* Product Image */}
          <Card mb="20px">
            <Image
              borderRadius="20px"
              h={{ base: 'auto', xl: '396px', '2xl': 'auto' }}
              src={
                image && typeof image === 'object'
                  ? URL.createObjectURL(image)
                  : image || 'https://via.placeholder.com/396'
              }
              alt={productName || 'Product Image'}
              fallbackSrc="https://via.placeholder.com/396" // Optional: Placeholder image
              onLoad={() => {}}
              onError={(err) => {
                console.error('Error loading image:', err);
                toast({
                  title: 'Image Load Error',
                  description: 'There was an error loading the product image.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              }}
            />
          </Card>
          {/* Product Info Form */}
          <Card p="30px">
            <Flex direction="column">
              {/* Product Name */}
              <InputField
                mb="20px"
                id="productName"
                label="Product Name"
                placeholder="Product Name"
                value={productName}
                onChange={(e) => {
                  setProductName(e.target.value);
                }}
                isRequired
              />
              {/* Product Description using InputField with variant="textarea" */}
              <InputField
                mb="20px"
                id="productDescription"
                label="Product Description"
                placeholder="Product Description"
                value={productDescription}
                onChange={(e) => {
                  setProductDescription(e.target.value);
                }}
                variant="textarea" // Specify the variant to render a Textarea
                isRequired
              />
              {/* Quantity Input */}
              <InputField
                mb="20px"
                id="quantity"
                label="Stock"
                placeholder="Quantity"
                type="number"
                value={quantity}
                onChange={(e) => handleQuantityChange(e.target.value)}
                isRequired
              />
              {/* Sold Quantity Display */}
              <Text mb="10px">
                <strong>Sold Quantity:</strong> {soldQuantity}
              </Text>
            </Flex>
            {/* Featured Product Switch */}
            <Flex align="center" mt="10px">
              <Text fontWeight="bold" mr="10px">
                Featured Product
              </Text>
              <Switch
                id="isFeatured"
                isChecked={isFeatured}
                onChange={(e) => {
                  setIsFeatured(e.target.checked);
                }}
              />
            </Flex>
          </Card>
        </Flex>
        {/* Right Column */}
        <Flex direction="column">
          {/* Dropzone component */}
          <Card p="30px" mb="20px">
            <Text fontSize="xl" fontWeight="bold" mb="10px">
              Product Images
            </Text>
            <Dropzone
              onDrop={(acceptedFiles) => handleImageChange(acceptedFiles)}
            >
              <Flex
                direction="column"
                align="center"
                justify="center"
                h="200px"
              >
                <Icon as={MdOutlineCloudUpload} w="50px" h="50px" mb="8px" />
                <Text fontSize="lg" fontWeight="bold">
                  Drag and drop an image here, or click to select a file
                </Text>
                <Text fontSize="sm" color="gray.500">
                  PNG, JPG, and GIF files are allowed
                </Text>
              </Flex>
            </Dropzone>
            {/* Display selected file details */}
            {image && typeof image === 'object' && (
              <Box mt="10px">
                <Text fontSize="sm" color="gray.600">
                  Selected file: {image.name} ({(image.size / 1024).toFixed(2)}{' '}
                  KB)
                </Text>
              </Box>
            )}
          </Card>
          {/* Details component */}
          <Details
            productPrice={productPrice}
            setProductPrice={(value) => {
              setProductPrice(value);
            }}
          />
          {/* Save Button */}
          <Button
            mt="20px"
            colorScheme="blue"
            onClick={handleSave}
            isDisabled={!productName || !productPrice}
          >
            Save Changes
          </Button>
          {/* Delete Button */}
          <Button mt="20px" colorScheme="red" onClick={onOpen}>
            Delete Product
          </Button>
        </Flex>
      </SimpleGrid>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete this product? This action cannot
              be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
