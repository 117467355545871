import React from 'react';
import { Avatar, Box } from '@chakra-ui/react';

const CustomAvatar = React.memo(
  ({ name, src, size = 'md', ...props }) => {
    return (
      <Box
        position="relative"
        overflow="cover"
        borderRadius="full"
        transition="transform 0.2s ease, opacity 0.2s ease"
        _hover={{ transform: 'scale(1.05)' }}
      >
        <Avatar
          name={name}
          src={src}
          size={size}
          bg="#11047A"
          color="white"
          objectFit="cover"
          borderRadius="full"
          {...props}
        />
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.name === nextProps.name && prevProps.src === nextProps.src,
);

export default CustomAvatar;
