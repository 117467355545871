import React, { useCallback, useEffect, forwardRef } from 'react';
import { BlockNoteView, useBlockNote, getDefaultReactSlashMenuItems } from '@blocknote/react';
import { defaultBlockSchema } from "@blocknote/core";
import '@blocknote/core/style.css';
import { Benefits } from './blocks/Benefits';
import { RiLayoutRowLine } from 'react-icons/ri';

const DEFAULT_BLOCK = [
    {
        type: "paragraph",
        content: [{ type: "text", text: "Start writing here..." }]
    }
];

const parseHtml = (html) => {
    if (!html) return DEFAULT_BLOCK;

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const blocks = [];

    doc.body.childNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node;
            let block = {
                type: 'paragraph',
                props: {
                    textAlignment: element.style.textAlign || 'left',
                    textColor: element.style.color || 'default',
                    backgroundColor: element.style.backgroundColor || 'default'
                },
                content: [],
            };

            // Handle headings
            if (element.tagName.match(/^H[1-6]$/)) {
                block.type = 'heading';
                block.props.level = parseInt(element.tagName[1]);
            }

            // Process text content
            element.childNodes.forEach((textNode) => {
                if (textNode.nodeType === Node.ELEMENT_NODE && textNode.tagName === 'SPAN') {
                    const span = textNode;
                    const styles = {
                        bold: span.style.fontWeight === 'bold',
                        italic: span.style.fontStyle === 'italic',
                        underline: span.style.textDecoration === 'underline',
                        strikethrough: span.style.textDecoration === 'line-through'
                    };

                    block.content.push({
                        type: 'text',
                        text: span.textContent || '',
                        styles: Object.keys(styles).some(key => styles[key]) ? styles : {}
                    });
                } else if (textNode.nodeType === Node.TEXT_NODE) {
                    block.content.push({
                        type: 'text',
                        text: textNode.textContent || '',
                        styles: {}
                    });
                }
            });

            blocks.push(block);
        }
    });

    return blocks.length > 0 ? blocks : DEFAULT_BLOCK;
};

const ensureValidContent = (content) => {
    if (typeof content === 'string') {
        return parseHtml(content);
    }
    if (!content || !Array.isArray(content) || content.length === 0) {
        return DEFAULT_BLOCK;
    }
    return content;
};

const editorConfig = {
    blockSchema: {
        ...defaultBlockSchema,
        benefits: Benefits,
    }
};

const CmsBlockEditor = forwardRef(({ value }, ref) => {
    const editor = useBlockNote({
        initialContent: ensureValidContent(value),
        blockSchema: editorConfig.blockSchema,
        onUpdate: ({ editor }) => {
            if (!editor.topLevelBlocks || editor.topLevelBlocks.length === 0) {
                editor.insertBlocks([{
                    type: "paragraph",
                    content: [{ type: "text", text: "" }]
                }], editor.getTextCursorPosition().block);
            }
        },
        slashCommands: (editor) => {
            const defaultCommands = getDefaultReactSlashMenuItems(editor);
            const benefitsCommand = {
                name: "benefits",
                title: "Benefits Section",
                group: "Basic blocks",
                icon: <RiLayoutRowLine />,
                hint: "Add a benefits section",
                execute: ({ editor }) => {
                    editor.insertBlocks([
                        {
                            type: "benefits",
                            props: { section: "section1" },
                            content: [{ type: "text", text: "" }],
                        }
                    ], editor.getTextCursorPosition().block);
                    return true;
                },
            };

            return [...defaultCommands, benefitsCommand];
        }
    });

    useEffect(() => {
        if (editor) {
            const validContent = ensureValidContent(value);
            if (JSON.stringify(editor.topLevelBlocks) !== JSON.stringify(validContent)) {
                editor.replaceBlocks(editor.topLevelBlocks, validContent);
            }
        }
    }, [editor, value]);

    const getContentAsHtml = useCallback(() => {
        if (!editor) return '';

        const tempDiv = document.createElement('div');
        const benefitsSection1 = [];
        const benefitsSection2 = [];
        const benefitsSection3 = [];

        editor.topLevelBlocks.forEach(block => {
            if (block.type === 'benefits') {
                const content = block.content.map(c => c.text).join('');
                switch (block.props.section) {
                    case 'section1': benefitsSection1.push(content); break;
                    case 'section2': benefitsSection2.push(content); break;
                    case 'section3': benefitsSection3.push(content); break;
                    default: break;
                }
            } else {
                let element;
                switch (block.type) {
                    case 'heading':
                        element = document.createElement(`h${block.props.level || 1}`);
                        break;
                    case 'paragraph':
                    default:
                        element = document.createElement('p');
                        break;
                }

                if (block.props?.textAlignment) {
                    element.style.textAlign = block.props.textAlignment;
                }

                if (block.props?.textColor && block.props.textColor !== 'default') {
                    element.style.color = block.props.textColor;
                }

                if (block.props?.backgroundColor && block.props.backgroundColor !== 'default') {
                    element.style.backgroundColor = block.props.backgroundColor;
                }

                block.content.forEach(content => {
                    if (content.type === 'text') {
                        const span = document.createElement('span');
                        span.textContent = content.text;

                        if (content.styles) {
                            if (content.styles.bold) span.style.fontWeight = 'bold';
                            if (content.styles.italic) span.style.fontStyle = 'italic';
                            if (content.styles.underline) span.style.textDecoration = 'underline';
                            if (content.styles.strikethrough) span.style.textDecoration = 'line-through';
                        }

                        element.appendChild(span);
                    }
                });

                tempDiv.appendChild(element);
            }
        });

        if (benefitsSection1.length || benefitsSection2.length || benefitsSection3.length) {
            const benefitsHtml = `
                <div>
                    <div class="max-w-6xl mx-auto space-x-4 mb-4 flex">
                        <div class="bg-white p-6 rounded-lg shadow-sm">
                            <div class="text-gray-700 max-w-5xl mx-auto">
                                ${benefitsSection1.join('')}
                            </div>
                        </div>
                        <div class="bg-white p-6 rounded-lg shadow-sm">
                            <div class="text-gray-700 max-w-5xl mx-auto">
                                ${benefitsSection2.join('')}
                            </div>
                        </div>
                    </div>
                    <div class="max-w-6xl mx-auto">
                        <div class="bg-white p-6 rounded-lg shadow-sm">
                            <div class="text-gray-700 max-w-5xl mx-auto">
                                ${benefitsSection3.join('')}
                            </div>
                        </div>
                    </div>
                </div>
            `;
            tempDiv.innerHTML = benefitsHtml;
        }

        return tempDiv.innerHTML;
    }, [editor]);

    React.useImperativeHandle(ref, () => ({
        getContent: getContentAsHtml
    }));

    if (!editor) {
        return null;
    }

    return (
        <div className="cms-block-editor" style={{ border: '1px solid #eee' }}>
            <BlockNoteView editor={editor} theme="light" />
        </div>
    );
});

CmsBlockEditor.displayName = 'CmsBlockEditor';

export { CmsBlockEditor };
