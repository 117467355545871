import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdDiscount,
  MdOutlineShoppingCartCheckout,
} from 'react-icons/md';
import { FaGear, FaUser, FaIndustry } from 'react-icons/fa6';
import { BsBookmarkHeartFill } from 'react-icons/bs';
import { RiShieldUserFill } from 'react-icons/ri';

// Admin Imports
// import DashboardsDefault from 'views/admin/dashboards/default';
import Dashboard from 'pages/dashboard/Dashboard';

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountApplications from 'views/admin/main/account/application';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';

import UsersOverview from 'pages/users/UsersOverview';
import UserDetail from 'pages/users/UserDetail';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import EcommerceNewProduct from 'pages/products/newProduct';
import EcommerceProductOverview from 'pages/products/overviewProduct';
import EcommerceProductSettings from 'pages/products/editProduct';
// import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
// import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
// import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
//Orders
import OrdersOverview from 'pages/orders/ordersOverview';
import OrderDetail from 'pages/orders/orderDetail';

//Discounts
import DiscountOverview from 'pages/discounts/discountOverview';
import CreateDiscount from 'pages/discounts/createDiscount';
import EditDiscount from 'pages/discounts/editDiscount';
// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault.jsx';
import LockCentered from 'views/auth/lock/LockCentered.jsx';
import LockDefault from 'views/auth/lock/LockDefault.jsx';
import SignIn from 'views/auth/signIn/SignIn.jsx';
import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
import SignUpDefault from 'views/auth/signUp/SignUpDefault.jsx';
import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';
import VerificationDefault from 'views/auth/verification/VerificationDefault.jsx';
import Callback from './pages/auth/Callback';
import GoogleAuthCallback from 'views/auth/signIn/GoogleAuthCallback';

// Perxify built pages
import AutomationBuilder from 'pages/automation/index';
import AutomationOverview from 'pages/automation/AutomationOverview';
import LoyaltyOverview from 'pages/loyalty/loyaltyOverview';
import LoyaltyDetail from 'pages/loyalty/loyaltyDetail';

// Companies Pages
import CompaniesOverview from 'pages/companies/CompaniesOverview';
import CompaniesDetail from 'pages/companies/CompaniesDetail';
import CompanyAdd from 'pages/companies/CompanyAdd';
import CompanySettings from 'pages/settings/companySettings';
// import Settings from 'views/admin/main/profile/settings';

// Privacy Policy & Terms of Service
import PrivacyPolicy from 'pages/terms/PrivacyPolicy';
import TermsOfService from 'pages/terms/TermsOfService';

const routes = [
  // --- Dashboard ---
  {
    key: 'dashboard',
    name: 'Dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    path: '/dashboard',
    layout: '/admin',
    component: <Dashboard />,
  },
  {
    key: 'loyalty-programs',
    name: 'Loyalty Programs',
    path: '/loyalty-programs',
    layout: '/admin',
    exact: false,
    icon: (
      <Icon
        as={BsBookmarkHeartFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <LoyaltyOverview />,
  }, // --- Automations ---
  {
    key: 'automations',
    name: 'Automations',
    path: '/automations',
    layout: '/admin',
    exact: false,
    icon: <Icon as={FaGear} width="20px" height="20px" color="inherit" />,
    component: <AutomationOverview />,
  },
  // --- Discounts ---
  {
    key: 'discounts',
    name: 'Discounts',
    path: '/discounts',
    layout: '/admin',
    exact: false,
    icon: <Icon as={MdDiscount} width="20px" height="20px" color="inherit" />,
    component: <DiscountOverview />,
  },
  {
    key: 'create-discount',
    name: 'Create Discount',
    path: '/discounts/new-discount',
    layout: '/admin',
    exact: false,
    hidden: true,
    component: <CreateDiscount />,
  },
  {
    key: 'edit-discount',
    name: 'Edit Discount',
    path: '/discounts/edit/:uuid',
    layout: '/admin',
    exact: false,
    hidden: true,
    component: <EditDiscount />,
  },
  // --- Orders ---
  {
    key: 'orders',
    name: 'Orders',
    path: '/orders',
    layout: '/admin',
    exact: false,
    icon: (
      <Icon
        as={MdOutlineShoppingCartCheckout}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <OrdersOverview />,
  },
  {
    key: 'order-detail',
    name: 'Order Detail',
    layout: '/admin',
    path: '/orders/:uuid',
    exact: false,
    hidden: true,
    component: <OrderDetail />,
  },
  // --- Products ---
  {
    key: 'products',
    name: 'Products',
    path: '/products',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    layout: '/admin',
    exact: false,
    component: <EcommerceProductOverview />,
  },

  // // --- Main pages ---
  {
    key: 'main-pages',
    name: 'Main Pages',
    path: '/main',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    hidden: true,
    items: [
      {
        name: 'Account',
        path: '/main/account',
        collapse: true,
        items: [
          {
            name: 'Billing',
            layout: '/admin',
            path: '/main/account/billing',
            exact: false,
            component: <AccountBilling />,
          },
          {
            name: 'Application',
            layout: '/admin',
            path: '/main/account/application',
            exact: false,
            component: <AccountApplications />,
          },
          {
            name: 'Invoice',
            layout: '/admin',
            path: '/main/account/invoice',
            exact: false,
            component: <AccountInvoice />,
          },
          {
            name: 'Settings',
            layout: '/admin',
            path: '/main/account/settings',
            exact: false,
            component: <AccountSettings />,
          } /*          {
                      name: 'All Courses',
                      layout: '/admin',
                      path: '/main/account/all-courses',
                      exact: false,
                      component: <AccountAllCourses />,
                    },
                    {
                      name: 'Course Page',
                      layout: '/admin',
                      path: '/main/account/course-page',
                      exact: false,
                      component: <AccountCoursePage />,
                    },*/,
        ],
      }, // --- Companies ---

      {
        name: 'Profile',
        path: '/main/profile',
        collapse: true,
        items: [
          {
            name: 'Profile Overview',
            layout: '/admin',
            path: '/main/profile/overview',
            exact: false,
            component: <ProfileOverview />,
          },
          {
            name: 'Profile Settings',
            layout: '/admin',
            path: '/main/profile/settings',
            exact: false,
            component: <ProfileSettings />,
          },
          {
            name: 'News Feed',
            layout: '/admin',
            path: '/main/profile/newsfeed',
            exact: false,
            component: <ProfileNewsfeed />,
          },
        ],
      },
      {
        name: 'Others',
        path: '/main/others',
        collapse: true,
        items: [
          {
            name: 'Notifications',
            layout: '/admin',
            path: '/main/others/notifications',
            exact: false,
            component: <OthersNotifications />,
          },
          {
            name: 'Pricing',
            layout: '/auth',
            path: '/main/others/pricing',
            exact: false,
            component: <OthersPricing />,
          },
          {
            name: '404',
            layout: '/admin',
            path: '/main/others/404',
            exact: false,
            component: <OthersError />,
          },
          {
            name: 'Messages',
            layout: '/admin',
            path: '/main/others/messages',
            exact: false,
            component: <Messages />,
          },
        ],
      },
    ],
  },

  // --- Authentication ---
  {
    key: 'authentication',
    name: 'Authentication',
    path: '/auth',
    hidden: true,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Log In',
        layout: '/auth',
        path: '/login',
        component: <SignIn />,
      },

      {
        name: 'Callback',
        layout: '/auth',
        path: '/callback',
        component: <Callback />,
      },
      {
        name: 'Google Auth Callback',
        layout: '/auth',
        path: '/google-auth-callback',
        component: <GoogleAuthCallback />,
      },

      // --- Sign In ---

      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/default',
            component: <SignUpDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-up/centered',
            component: <SignUpCentered />,
          },
        ],
      }, // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      }, // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: <LockDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: <LockCentered />,
          },
        ],
      }, // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: <ForgotPasswordDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },
  {
    key: 'users',
    name: 'Users',
    layout: '/admin',
    path: '/users',
    exact: false,
    icon: <Icon as={FaUser} width="20px" height="20px" color="inherit" />,
    component: <UsersOverview />,
  },
  {
    key: 'super-admin',
    name: 'Super Admin',
    path: '/superadmin',
    collapse: true,
    icon: (
      <Icon as={RiShieldUserFill} width="20px" height="20px" color="inherit" />
    ),
    items: [
      {
        key: 'companies',
        name: 'Companies',
        path: '/superadmin/companies',
        layout: '/admin',
        exact: true, // Set exact to true
        component: <CompaniesOverview />,
        icon: (
          <Icon as={FaIndustry} width="20px" height="20px" color="inherit" />
        ),
      },
    ],
  },

  // --- Hidden Routes ---
  {
    key: 'company-detail',
    name: 'Company Detail',
    layout: '/admin',
    path: '/superadmin/companies/:companyUuid', // Matches the dynamic route
    exact: true,
    component: <CompaniesDetail />,
    hidden: true,
  },
  {
    key: 'add-company',
    name: 'Add Company',
    layout: '/admin',
    path: '/superadmin/companies/add',
    component: <CompanyAdd />,
    hidden: true,
  },

  // --- Hidden Routes ---
  {
    key: 'new-product',
    name: 'New Product',
    layout: '/admin',
    path: '/products/new-product',
    exact: false,
    hidden: true,
    component: <EcommerceNewProduct />,
  },
  {
    key: 'product-edit',
    name: 'Product Edit',
    layout: '/admin',
    path: '/products/edit/:uuid',
    exact: false,
    hidden: true,
    component: <EcommerceProductSettings />,
  },
  {
    key: 'company-settings',
    name: 'Company Settings',
    path: '/company/settings',
    layout: '/admin',
    hidden: true,
    exact: false,
    component: <CompanySettings />,
  },
  {
    key: 'automation-builder',
    name: 'Automation Builder',
    path: '/automations/builder/:uuid?',
    layout: '/admin',
    exact: false,
    hidden: true,
    component: <AutomationBuilder />,
  },
  {
    key: 'user-detail',
    name: 'User Detail',
    layout: '/admin',
    path: '/users/user/:userUuid',
    exact: false,
    hidden: true,
    component: <UserDetail />,
  },
  {
    key: 'loyalty-detail',
    name: 'Loyalty Detail',
    layout: '/admin',
    path: '/loyalty-programs/:uuid',
    exact: false,
    hidden: true,
    component: <LoyaltyDetail />,
  },
  {
    key: 'profile-settings',
    name: 'Profile settings',
    layout: '/admin',
    path: '/admin/main/profile/settings',
    exact: false,
    hidden: true,
    component: <AccountSettings />,
  },
  {
    key: 'privacy-policy',
    name: 'Privacy Policy',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    path: '/privacy-policy',
    layout: '/admin',
    hidden: true,
    component: <PrivacyPolicy />,
  },
  {
    key: 'terms-of-service',
    name: 'Terms of Service',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    path: '/terms-of-service',
    layout: '/admin',
    hidden: true,
    component: <TermsOfService />,
  },
];

export default routes;
