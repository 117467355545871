import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const bgColor = useColorModeValue('white', 'navy.800');
  const linkColor = useColorModeValue('blue.600', 'blue.300');

  return (
    <Box pt={{ sm: '125px', lg: '75px' }} pb="20px">
      <Container maxW="container.lg" bg={bgColor} borderRadius="lg" p={8} shadow="sm">
        <VStack spacing={6} align="stretch">
          <Heading as="h1" size="xl" color={textColor}>
            Privacy Policy
          </Heading>

          <Text color={textColor}>
            Last updated: October 29, 2024
          </Text>

          <Box>
            <Heading as="h2" size="md" color={textColor} mb={2}>
              Company Information
            </Heading>
            <Text color={textColor}>
              This Privacy Policy applies to services provided by Alder Solutions OÜ ("we", "us", "our"),
              registered in Estonia. Our services are governed by Estonian law and comply with EU regulations.
            </Text>
            <VStack spacing={2} pl={4} mt={2} align="stretch">
              <Text color={textColor}>Company: Alder Solutions OÜ</Text>
              <Text color={textColor}>Location: Tallinn, Estonia</Text>
              <Text color={textColor}>Data Protection Contact: <Link color={linkColor} href="mailto:info@perxify.com">info@perxify.com</Link></Text>
            </VStack>
          </Box>

          [Previous sections remain the same...]

          <Box>
            <Heading as="h2" size="md" color={textColor} mb={2}>
              5. User Rights and Control
            </Heading>
            <Text color={textColor}>
              Under EU GDPR and Estonian law, you have comprehensive rights over your data. To exercise these rights,
              please contact us at <Link color={linkColor} href="mailto:info@perxify.com">info@perxify.com</Link>:
            </Text>
            <VStack spacing={2} pl={4} mt={2} align="stretch">
              <Text color={textColor}>• Access and export your data</Text>
              <Text color={textColor}>• Modify or delete your information</Text>
              <Text color={textColor}>• Opt-out of specific data collection</Text>
              <Text color={textColor}>• Control marketing communications</Text>
              <Text color={textColor}>• Request data processing information</Text>
              <Text color={textColor}>• Lodge a complaint with the Estonian Data Protection Inspectorate</Text>
            </VStack>
          </Box>

          [Previous sections remain the same...]

          <Box>
            <Heading as="h2" size="md" color={textColor} mb={2}>
              8. Contact and Complaints
            </Heading>
            <Text color={textColor}>
              For any privacy-related queries, data deletion requests, or GDPR-related matters, please contact our
              Data Protection team at <Link color={linkColor} href="mailto:info@perxify.com">info@perxify.com</Link>.
              We aim to respond to all requests within 30 days as required by GDPR.
            </Text>
            <Text color={textColor} mt={2}>
              If you are unsatisfied with our response, you have the right to lodge a complaint with the
              Estonian Data Protection Inspectorate (AKI).
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;