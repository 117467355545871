import {
  Flex,
  Grid,
  useColorModeValue,
  Input,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
// Custom components
import SalesWidget from 'components/dashboard/OverallRevenueWidget';
import UsersOverviewWidget from 'components/dashboard/UsersOverviewWidget';
import LoyaltyProgramsWidget from 'components/dashboard/LoyaltyProgramWidget';
import LatestLoyaltyPrograms from 'components/dashboard/LatestLoyaltyProgramsWidget';
import LatestProducts from 'components/dashboard/LatestProductsWidget';
import CreditBalanceWidget from 'components/dashboard/CreditBalanceWidget';
import { VSeparator } from 'components/separator/Separator';
import { subDays, subMonths, subYears, format } from 'date-fns';

export default function Default() {
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState('lastYear');

  useEffect(() => {
    const today = new Date();
    switch (dateRange) {
      case 'last7days':
        setStartDate(format(subDays(today, 7), 'yyyy-MM-dd'));
        setEndDate(format(today, 'yyyy-MM-dd'));
        break;
      case 'lastMonth':
        setStartDate(format(subMonths(today, 1), 'yyyy-MM-dd'));
        setEndDate(format(today, 'yyyy-MM-dd'));
        break;
      case 'lastYear':
        setStartDate(format(subYears(today, 1), 'yyyy-MM-dd'));
        setEndDate(format(today, 'yyyy-MM-dd'));
        break;
      case 'custom':
        // Do nothing, custom dates will be set manually
        break;
      default:
        setStartDate('');
        setEndDate('');
    }
  }, [dateRange]);

  return (
    <Flex direction="column" pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex mb="20px" alignItems="center">
        <RadioGroup
          onChange={setDateRange}
          value={dateRange}
          defaultValue="lastYear"
        >
          <Flex>
            <Radio value="last7days" mr="20px">
              Last 7 days
            </Radio>
            <Radio value="lastMonth" mr="20px">
              Last Month
            </Radio>
            <Radio value="lastYear" mr="20px">
              Last Year
            </Radio>
            <Radio value="custom" mr="20px">
              Custom
            </Radio>
          </Flex>
        </RadioGroup>

        <Flex pb="20px">
          <FormControl mr="10px">
            <FormLabel fontSize="xs">Start Date</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={dateRange !== 'custom'}
              size="sm"
              borderRadius="md"
            />
          </FormControl>
          <FormControl mr="10px">
            <FormLabel fontSize="xs">End Date</FormLabel>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={dateRange !== 'custom'}
              size="sm"
              borderRadius="md"
            />
          </FormControl>
        </Flex>
      </Flex>

      <Grid templateColumns={{ base: '1fr', xl: '5fr auto 3fr' }} gap="20px">
        {/* Left Column - Graph Widgets */}
        <Flex
          direction="column"
          gap="20px"
          gridColumn={{ base: '1 / -1', xl: '1 / 2' }}
        >
          <SalesWidget startDate={startDate} endDate={endDate} />
          <UsersOverviewWidget startDate={startDate} endDate={endDate} />
          <LoyaltyProgramsWidget startDate={startDate} endDate={endDate} />
        </Flex>

        {/* Vertical Separator */}
        <Flex
          display={{ base: 'none', xl: 'flex' }}
          justifyContent="center"
          gridColumn="2 / 3"
        >
          <VSeparator h="100%" bg={paleGray} />
        </Flex>

        {/* Right Column - Smaller Widgets */}
        <Flex
          direction="column"
          gap="20px"
          gridColumn={{ base: '1 / -1', xl: '3 / 4' }}
        >
          <CreditBalanceWidget />
          <LatestLoyaltyPrograms />
          <LatestProducts />
        </Flex>
      </Grid>
    </Flex>
  );
}
