// components/Pagination.js
import React from 'react';
import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

export default function Pagination({
  pageIndex,
  pageCount,
  canNextPage,
  canPreviousPage,
  previousPage,
  nextPage,
  setPageIndex,
  textColor,
  borderColor,
  brandColor,
}) {
  const createPages = (count) => {
    let arrPageCount = [];
    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };

  return (
    <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
      <Button
        variant="no-effects"
        onClick={previousPage}
        disabled={!canPreviousPage}
        transition="all .5s ease"
        w="40px"
        h="40px"
        borderRadius="50%"
        bg="transparent"
        border="1px solid"
        borderColor={borderColor}
        _hover={{
          bg: 'whiteAlpha.100',
          opacity: '0.7',
        }}
        aria-label="Previous Page"
      >
        <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
      </Button>
      
      {createPages(pageCount).map((pageNumber, index) => (
        <Button
          key={index}
          variant="no-effects"
          transition="all .5s ease"
          onClick={() => setPageIndex(pageNumber - 1)}
          w="40px"
          h="40px"
          borderRadius="50%"
          bg={pageNumber === pageIndex + 1 ? brandColor : 'transparent'}
          border={
            pageNumber === pageIndex + 1 ? 'none' : '1px solid lightgray'
          }
          _hover={
            pageNumber === pageIndex + 1
              ? {
                  opacity: '0.7',
                }
              : {
                  bg: 'whiteAlpha.100',
                }
          }
          aria-current={pageNumber === pageIndex + 1 ? 'page' : undefined}
          aria-label={`Page ${pageNumber}`}
        >
          <Text
            fontSize="sm"
            color={pageNumber === pageIndex + 1 ? '#fff' : textColor}
          >
            {pageNumber}
          </Text>
        </Button>
      ))}

      <Button
        variant="no-effects"
        onClick={nextPage}
        disabled={!canNextPage}
        transition="all .5s ease"
        w="40px"
        h="40px"
        borderRadius="50%"
        bg="transparent"
        border="1px solid"
        borderColor={borderColor}
        _hover={{
          bg: 'whiteAlpha.100',
          opacity: '0.7',
        }}
        aria-label="Next Page"
      >
        <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
      </Button>
    </Stack>
  );
}
