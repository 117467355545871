// Dropzone.js

import { Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import React from "react";

function Dropzone(props) {
  const { onDrop, children, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 });
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("gray.300", "whiteAlpha.100");

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w="100%"
      maxW="100%"
      h="max-content"
      minH="100px"
      cursor="pointer"
      {...getRootProps()}
      pt="10px !important"
      pb="10px !important"
      {...rest}
    >
      <Input {...getInputProps()} />
      {children}
    </Flex>
  );
}

export default Dropzone;
