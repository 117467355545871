import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Flex, Text, Button, Card, Tabs, TabList, TabPanels, Tab, TabPanel, useToast, Alert, AlertIcon, Spinner } from '@chakra-ui/react';
import { getTranslation, setTranslation } from 'utils/translationUtils';
import { CmsBlockEditor } from './CmsBlockEditor';

const DEFAULT_BLOCK = [
  {
    type: "paragraph",
    content: [{ type: "text", text: "Start writing here..." }]
  }
];

const BLOCK_CONFIG = {
  hero: {
    key: 'cms_content_hero',
    title: 'Hero'
  },
  overview: {
    key: 'cms_content_overview',
    title: 'Overview'
  },
  footer: {
    key: 'cms_content_footer',
    title: 'Footer'
  },
  benefits: {
    key: 'cms_content_benefits',
    title: 'Benefits'
  },
  highlights: {
    key: 'cms_content_highlights',
    title: 'Highlights'
  }
};

export default function CmsBlocks() {
  const [blocks, setBlocks] = useState({
    en: Object.keys(BLOCK_CONFIG).reduce((acc, key) => ({
      ...acc,
      [BLOCK_CONFIG[key].key]: DEFAULT_BLOCK
    }), {}),
    et: Object.keys(BLOCK_CONFIG).reduce((acc, key) => ({
      ...acc,
      [BLOCK_CONFIG[key].key]: DEFAULT_BLOCK
    }), {})
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const editorsRef = useRef({
    en: {},
    et: {}
  });

  const fetchCmsBlocks = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    try {
      const newBlocks = {
        en: {},
        et: {}
      };

      // Initialize with default blocks
      Object.keys(BLOCK_CONFIG).forEach(key => {
        newBlocks.en[BLOCK_CONFIG[key].key] = DEFAULT_BLOCK;
        newBlocks.et[BLOCK_CONFIG[key].key] = DEFAULT_BLOCK;
      });

      // Fetch translations for each block
      for (const [_, config] of Object.entries(BLOCK_CONFIG)) {
        const translations = await getTranslation(config.key);
        if (translations) {
          Object.entries(translations).forEach(([lang, content]) => {
            if (lang in newBlocks) {
              newBlocks[lang][config.key] = content;
            }
          });
        }
      }

      setBlocks(newBlocks);
    } catch (error) {
      console.error('Error fetching cms blocks:', error);
      setError('Failed to load CMS blocks');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCmsBlocks();
  }, [fetchCmsBlocks]);

  const handleSaveCmsBlocks = async () => {
    setIsSaving(true);
    setError(null);

    try {
      const updatedBlocks = {
        en: {},
        et: {}
      };

      ['en', 'et'].forEach(lang => {
        Object.keys(BLOCK_CONFIG).forEach(blockKey => {
          const editor = editorsRef.current[lang][blockKey];
          if (editor) {
            updatedBlocks[lang][BLOCK_CONFIG[blockKey].key] = editor.getContent();
          }
        });
      });

      // Save each block for each language
      for (const [lang, blockContents] of Object.entries(updatedBlocks)) {
        for (const [key, content] of Object.entries(blockContents)) {
          await setTranslation(
            key,
            content, // Store HTML content directly
            lang
          );
        }
      }

      toast({
        title: 'Changes saved successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.error('Error saving cms blocks:', error);
      setError('Failed to save changes');
      toast({
        title: 'Error saving changes',
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const renderEditors = (language) =>
    Object.keys(BLOCK_CONFIG).map((blockKey) => (
      <React.Fragment key={blockKey}>
        <Text fontSize="xl" fontWeight="medium" mt={4} mb={2} pl={4}>
          {BLOCK_CONFIG[blockKey].title}
        </Text>
        <CmsBlockEditor
          key={`${language}-${blockKey}`}
          ref={(ref) => {
            if (ref) {
              editorsRef.current[language][blockKey] = ref;
            }
          }}
          value={blocks[language][BLOCK_CONFIG[blockKey].key]}
        />
      </React.Fragment>
    ));

  if (isLoading) {
    return (
      <Card mb="20px" w="100%">
        <Flex justify="center" align="center" p={8}>
          <Spinner />
        </Flex>
      </Card>
    );
  }

  return (
    <Card mb="20px" w="100%" shadow="sm">
      <Flex direction="column" p={5}>
        <Flex align="center" justify="space-between" mb={5}>
          <Text fontSize="2xl" fontWeight="bold">
            CMS Blocks
          </Text>
          <Button
            colorScheme="blue"
            onClick={handleSaveCmsBlocks}
            isLoading={isSaving}
            loadingText="Saving..."
          >
            Save Changes
          </Button>
        </Flex>

        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}

        <Tabs variant="enclosed">
          <TabList>
            <Tab>English</Tab>
            <Tab>Estonian</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>{renderEditors('en')}</TabPanel>
            <TabPanel>{renderEditors('et')}</TabPanel>
          </TabPanels>
        </Tabs>

        <Button
          colorScheme="blue"
          mt={5}
          onClick={handleSaveCmsBlocks}
          isLoading={isSaving}
          loadingText="Saving..."
        >
          Save Changes
        </Button>
      </Flex>
    </Card>
  );
}