// src/api/axios.js
import axios from 'axios';
import cookie from 'cookie';

// Create an axios instance with the base URL from the environment variable
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Use the variable from .env injected by Webpack    
    headers: {
        'Content-Type': 'application/json', // Default content type    
    }
});

const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[name];
};

// Add a request interceptor to include tokens and handle multipart/form/data if needed
apiClient.interceptors.request.use((config) => {
    const CompanyToken = getCookie('x-company');
    const UserToken = getCookie('token');

    // Automatically set tokens in headers
    if (CompanyToken) {
        config.headers['x-company'] = CompanyToken;
    }
    if (UserToken) {
        config.headers.Authorization = `Bearer ${UserToken}`;
    }

    // Automatically set 'Content-Type: multipart/form-data' if the request data is FormData
    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default apiClient;
