import React from 'react';
import { Flex, Select, Checkbox, Button, Text } from '@chakra-ui/react';

const DiscountFilters = ({
  productUUID,
  setProductUUID,
  loyaltyProgramUUID,
  setLoyaltyProgramUUID,
  loyaltyLevelUUID,
  setLoyaltyLevelUUID,
  isActive,
  setIsActive,
  resetFilters,
  filteredProducts,
  filteredLoyaltyPrograms,
  filteredLoyaltyLevels,
  setPagination,
}) => {
  return (
    <Flex>
      {/* Product Filter */}
      <Select
        value={productUUID}
        onChange={(e) => {
          setProductUUID(e.target.value);
          setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        }}
        mr="10px"
        w="200px"
        placeholder="All Products"
      >
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <option key={product.uuid} value={product.uuid}>
              {product.name}
            </option>
          ))
        ) : (
          <option disabled>No Products with Discounts</option>
        )}
      </Select>

      {/* Loyalty Program Filter */}
      <Select
        value={loyaltyProgramUUID}
        onChange={(e) => {
          setLoyaltyProgramUUID(e.target.value);
          setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        }}
        mr="10px"
        w="200px"
        placeholder="All Loyalty Programs"
      >
        {filteredLoyaltyPrograms.length > 0 ? (
          filteredLoyaltyPrograms.map((program) => (
            <option key={program.uuid} value={program.uuid}>
              {program.name}
            </option>
          ))
        ) : (
          <option disabled>No Loyalty Programs with Discounts</option>
        )}
      </Select>

      {/* Loyalty Level Filter */}
      <Select
        value={loyaltyLevelUUID}
        onChange={(e) => {
          setLoyaltyLevelUUID(e.target.value);
          setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        }}
        mr="10px"
        w="200px"
        placeholder="All Loyalty Levels"
      >
        {filteredLoyaltyLevels.length > 0 ? (
          filteredLoyaltyLevels.map((level) => (
            <option key={level.uuid} value={level.uuid}>
              {level.name}
            </option>
          ))
        ) : (
          <option disabled>No Loyalty Levels with Discounts</option>
        )}
      </Select>

      {/* Active Status Filter and Reset Filters Button */}
      <Flex alignItems="center" ml="10px">
        <Checkbox
          isChecked={isActive}
          onChange={(e) => {
            setIsActive(e.target.checked);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
          }}
          mr="10px"
        >
          Active
        </Checkbox>

        <Button
          onClick={resetFilters}
          colorScheme="gray"
          variant="outline"
          size="sm"
        >
          Reset Filters
        </Button>
      </Flex>
    </Flex>
  );
};

export default DiscountFilters;
