export const convertToDays = (value, period) => {
  switch (period) {
    case 'days':
      return value;
    case 'weeks':
      return value * 7;
    case 'months':
      return value * 30;
    case 'years':
      return value * 365;
    default:
      return value;
  }
};

export const convertFromDays = (value) => {
  if (!value) {
    return 'Never';
  }
  if (value % 365 === 0) {
    return `${value / 365} years`;
  } else if (value % 30 === 0) {
    return `${value / 30} months`;
  } else if (value % 7 === 0) {
    return `${value / 7} weeks`;
  } else {
    return `${value} days`;
  }
};
